import React, { Component } from 'react';
// import { hot } from 'react-hot-loader';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import authentication from './services/authentication';
import 'bootstrap/scss/bootstrap.scss';
import './App.scss';

import Header from './components/header';
import Footer from './components/footer';
import Login from './components/login';
import Register from './components/register';
import PasswordReset from './components/passwordReset';
import Home from './components/home';
import PageNotFound from './components/404';
import Application from './components/application';
import Resume from './components/resume';
import Terms from './components/terms';
import PersonalTaxCredits from './components/tax';
import ManufacturingPolicy from './components/manufacturingPolicy';
import Quiz from './components/quiz';
import Admin from './components/admin';
import { workers as workersActions } from './actions';

const { loggedIn, superAdmin } = authentication;

class App extends Component {
  constructor({ location, history }) {
    super(...arguments);
    const { pathname } = location;
    const publicRoutes = ['/login', '/register', '/password-reset-request', '/password-reset-complete'];
    if(publicRoutes.indexOf(pathname) === -1 && !loggedIn()) {
      history.push('/login');
    }
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/" render={({history, location}) => {
            const { pathname } = location;
            const publicRoutes = ['/login', '/register', '/password-reset-request', '/password-reset-complete'];
            if(publicRoutes.indexOf(pathname) === -1 && !loggedIn()) {
              return <Redirect to="/login" />
            }
            return (
              <Switch>
                <Route exact path="/" component={({history}) => {
                  if(!authentication.loggedIn()) {
                    history.replace('/login');
                  } else if(superAdmin()) {
                    history.replace('/admin');
                  } else {
                    history.replace('/checklist');
                  }
                  return null;
                }} />
                <Route exact path="/404" render={props => {
                  document.title = '404 Page Not Found';
                  return <PageNotFound {...props} />
                }} />
                <Route exact path="/login" render={(props) => {
                  document.title = 'Login';
                  return (<Login {...props} />);
                }} />
                <Route exact path="/register" render={props => {
                  document.title = 'Register';
                  return (<Register {...props} />);
                }} />
                <Route exact path="/password-reset-request" render={props => {
                  document.title = 'Password Reset';
                  return (<PasswordReset.Request {...props} />);
                }} />
                <Route exact path="/password-reset-complete" render={props => {
                  document.title = 'Password Reset Complete';
                  return (<PasswordReset.Complete {...props} />);
                }} />
                <Route path="/" component={Protected} />
              </Switch>
            )
          }} />
        </Switch>
      </div>
    );
  }
}

class ProtectedRoutes extends Component {
  componentDidMount() {
    if(loggedIn() && !superAdmin()) {
      this.props.getChecklist();
    }
  }

  render() {
    const withHeader = Cmp => props => (
      <React.Fragment>
        <Header {...props} />
        <div className="col">
          <Cmp {...props} />
        </div>
        <Footer />
      </React.Fragment>
    );
    return (
      <Switch>
        { /*Worker routes*/ }
        <Route exact path="/checklist" render={props => {
          document.title = 'Checklist';
          return withHeader(Home)(props);
        }} />
        <Route exact path="/application" render={props => {
          document.title = 'Application';
          return withHeader(Application)(props);
        }} />
        <Route exact path="/resume" render={props => {
          document.title = 'Resume';
          return withHeader(Resume)(props);
        }} />
        <Route exact path="/terms-and-conditions" render={props => {
          document.title = 'Terms and Conditions';
          return withHeader(Terms)(props);
        }} />
        <Route exact path="/health-and-safety" render={props => {
          document.title = 'Health and Safety at Work';
          return withHeader(Quiz.HealthSafety)(props);
        }} />
        <Route exact path="/whmis" render={props => {
          document.title = 'WHMIS 2015 Testing';
          return withHeader(Quiz.Whmis)(props);
        }} />
        <Route exact path="/personal-tax-credits" render={props => {
          document.title = 'Personal Tax Credit Return';
          return withHeader(PersonalTaxCredits)(props);
        }} />
        <Route exact path="/good-manufacturing-policy" render={props => {
          document.title = 'Good Manufacturing Practices Policy';
          return withHeader(ManufacturingPolicy)(props);
        }} />
        { /*Admin routes*/ }
        <Route exact path="/admin" render={props => {
          const { history } = props;
          if(!superAdmin()) {
            history.replace('/404');
            return null;
          }
          document.title = 'Dashboard';
          return withHeader(Admin.Dashboard)(props);
        }} />
        <Route exact path="/admin/applications" render={props => {
          const { history } = props;
          if(!superAdmin()) {
            history.replace('/404');
            return null;
          }
          document.title = 'Applications';
          return withHeader(Admin.Applications)(props);
        }} />
        <Route path="/" render={props => {
          return withHeader(PageNotFound)(props);
        }} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getChecklist: () => dispatch(workersActions.getChecklist())
  };
}
const Protected = connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);

//const hotApp = hot(module)(App);
export default withRouter(App);
