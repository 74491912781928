import _ from 'lodash';
import authentication from '../services/authentication';
import application from '../services/application';
import doc from '../services/doc';
import quiz from '../services/quiz';
import employee from '../services/employee';
import resources from '../services/resources';
import checklist from '../services/checklist';

const passwordResetSubmit = (formData) => dispatch => {
  dispatch({
    type: 'PASSWORD_RESET_WILL_SUBMIT'
  });
  employee.passwordReset(formData).then(() => dispatch({
    type: 'PASSWORD_RESET_DID_SUCCESS',
    payload: {
      success: true
    }
  })).catch(err => dispatch({
    type: 'PASSWORD_RESET_DID_FAIL',
    payload: {
      error: err.message
    }
  }));
};

const passwordResetStep2Fetch = token => dispatch => {
  employee.passwordResetStep2Fetch(token).then(data => {
    dispatch({
      type: 'PASSWORD_RESET_STEP2_FETCH',
      payload: data
    });
  }).catch(err => {
    dispatch({
      type: 'PASSWORD_RESET_STEP2_FETCH',
      payload: false
    });
  });
};

const passwordResetStep2Submit = (formData) => dispatch => {
  dispatch({
    type: 'PASSWORD_RESET_STEP2_WILL_SUBMIT'
  });
  employee.passwordResetStep2Submit(formData).then(() => dispatch({
    type: 'PASSWORD_RESET_STEP2_DID_SUCCESS',
    payload: {
      success: true
    }
  })).catch(err => dispatch({
    type: 'PASSWORD_RESET_STEP2_DID_FAIL',
    payload: {
      error: err.message
    }
  }));
};

const passwordResetResetState = () => dispatch => {
  dispatch({
    type: 'PASSWORD_RESET_RESET_STATE',
    payload: null
  });
}

const registerSubmit = (formData) => dispatch => {
  dispatch({
    type: 'REGISTER_WILL_SUBMIT'
  });
  employee.register(formData).then(() => dispatch({
    type: 'REGISTER_DID_SUCCESS',
    payload: {
      success: true
    }
  })).catch(err => dispatch({
    type: 'REGISTER_DID_FAIL',
    payload: {
      error: err.message
    }
  }));
};

const loginSubmit = (email, password) => dispatch => {
  dispatch({
    type: 'LOGIN_WILL_SUBMIT'
  });
  authentication.login(email, password).then(() => dispatch({
    type: 'LOGIN_DID_SUCCESS',
    payload: {
      success: true
    }
  })).catch(err => dispatch({
    type: 'LOGIN_DID_FAIL',
    payload: {
      error: err.message
    }
  }));
};
const applicationSubmit = ( formData ) => dispatch => {
  const formDataCopy = _.cloneDeep(formData);
  const applicationAction = (formDataCopy.id) ? application.update : application.save;
  delete(formDataCopy.id);
  const validation = application.validate(formDataCopy);
  if(!_.isEmpty(validation)) {
    return dispatch({
      type: 'APPLICATION_DID_VALIDATION_FAIL',
      payload: validation
    });
  }
  dispatch({
    type: 'APPLICATION_WILL_SAVE'
  });
  applicationAction('my-application', formDataCopy).then(data => {
    dispatch({
      type: 'CHECKLIST_ITEM_STATUS_UPDATE',
      payload: {
        key: 'application',
        status: true
      }
    });
    dispatch({
      type: 'APPLICATION_DID_SAVE_SUCCESS',
      payload: data
    });
  })
  .catch(err => dispatch({
    type: 'APPLICATION_DID_SAVE_FAIL',
    payload: err.error
  }));
}

const applicationPageLoad = {
  type: 'APPLICATION_STATE_RESET',
  payload: {}
};

const getJobPositionsList = () => dispatch => {
  resources.getJobPositions().then(jobPositions => dispatch({
    type: 'JOB_POSITIONS_DID_FETCH',
    payload: jobPositions
  }));
};

const getCitiesList = provinceId => dispatch => {
  resources.getCitiesList(provinceId).then(cities => dispatch({
    type: 'JOB_CITIES_DID_FETCH',
    payload: cities
  }));
};

const getMyApplication = () => dispatch => {
  dispatch({
    type: 'MY_APPLICATION_WILL_FETCH'
  });

  application.getApplication('my-application').then(application => {
    if(Array.isArray(application) && !application.length) application = {};
    dispatch({
      type: 'MY_APPLICATION_DID_FETCH',
      payload: _.assign(application, {email: authentication.getProfile().email})
    });
  });
};

const getLanguagesList = () => dispatch => {
  resources.getLanguagesList().then(languages => dispatch({
    type: 'LANGUAGES_DID_FETCH',
    payload: languages
  }));
};

// Fetch saved terms and conditions pdf file
const getTerms = () => dispatch => {
  dispatch({
    type: 'TERMS_WILL_FETCH'
  });

  doc.getOne({type: 'terms'}).then(terms => dispatch({
    type: 'TERMS_DID_FITCH',
    payload: terms
  }));
};

const termsSubmit = (formData) => dispatch => {
  dispatch({
    type: 'TERMS_WILL_SAVE'
  });

  doc.saveTerms({...formData}).then(terms => {
    dispatch({
      type: 'CHECKLIST_ITEM_STATUS_UPDATE',
      payload: {
        key: 'terms',
        status: true,
        deleted: false
      }
    });
    dispatch({
      type: 'TERMS_DID_SAVE_SUCCESS',
      payload: {
        file_exists: true
      }
    });
  }).catch(() => dispatch({
    type: 'TERMS_DID_SAVE_FAIL',
  }));
};

const getTaxCredit = () => dispatch => {
  dispatch({
    type: 'TAX_CREDIT_WILL_FETCH'
  });
  doc.getOne({type: 'tax-credit'})
    .then(taxCredit => dispatch({
      type: 'TAX_CREDIT_DID_FETCH',
      payload: taxCredit
    })
    ).catch(() => dispatch({
      type: 'TAX_CREDIT_DID_FETCH',
      payload: []
    }));
};

const getResume = () => dispatch => {
  dispatch({
    type: 'RESUME_WILL_FETCH'
  });
  doc.getOne({type: 'resume'})
    .then(resume => dispatch({
      type: 'RESUME_DID_FETCH',
      payload: resume
    })
    ).catch(() => dispatch({
      type: 'RESUME_DID_FETCH',
      payload: []
    }));
};

const taxCreditUpload = file => dispatch => {
  dispatch({
    type: 'TAX_CREDIT_WILL_UPLOAD'
  });
  Promise.resolve()
    .then(() => {
      if(!file) throw new Error('File is missing. Please select a pdf file.')
      return doc.saveTaxCredit(file);
    })
    .then(() => {
      dispatch({
        type: 'CHECKLIST_ITEM_STATUS_UPDATE',
        payload: {
          key: 'tax-credit',
          status: true,
          deleted: false
        }
      });
      dispatch({
        type: 'TAX_CREDIT_DID_UPLOAD_SUCCESS',
        payload: { file_exists: true, error: null }
      });
    })
    .catch(err => {
      dispatch({
        type: 'TAX_CREDIT_DID_UPLOAD_FAIL',
        payload: {
          file_exists: false,
          error: err
        }
      });
    });
};

const resumeUpload = file => dispatch => {
  dispatch({
    type: 'RESUME_WILL_UPLOAD'
  });
  Promise.resolve()
    .then(() => {
      if(!file) throw new Error('File is missing. Please select a file.')
      return doc.saveResume(file);
    })
    .then(() => {
      dispatch({
        type: 'CHECKLIST_ITEM_STATUS_UPDATE',
        payload: {
          key: 'resume',
          status: true,
          deleted: false
        }
      });
      dispatch({
        type: 'RESUME_DID_UPLOAD_SUCCESS',
        payload: { file_exists: true, error: null }
      });
    })
    .catch(err => {
      dispatch({
        type: 'RESUME_DID_UPLOAD_FAIL',
        payload: {
          file_exists: false,
          error: err
        }
      });
    });
};

const getChecklist = () => dispatch => {
  dispatch({
    type: 'CHECKLIST_WILL_FETCH'
  });

  checklist.fetch(authentication.getProfile().userId).then(items => {
    dispatch({
      type: 'CHECKLIST_DID_FETCH',
      payload: items
    });
  })
  .catch(() => {
    dispatch({
      type: 'CHECKLIST_DID_FETCH',
      payload: []
    });
  });
};

const getHealthSafety = () => dispatch => {
  dispatch({
    type: 'HEALTH_SAFETY_WILL_FETCH'
  });
  quiz.getHealthSafety()
    .then(healthSafety => {
      dispatch({
        type: 'HEALTH_SAFETY_DID_FETCH',
        payload: healthSafety
      });
    })
    .catch(err => {
      dispatch({
        type: 'HEALTH_SAFETY_DID_FETCH',
        payload: null
      });
    });
};

const submitHealthSafetyQuiz = formData => dispatch => {
  const validation = quiz.validateHealthSafety(formData);
  if(!_.isEmpty(validation)) {
    return dispatch({
      type: 'HEALTH_SAFETY_DID_VALIDATION_FAIL',
      payload: validation
    });
  }

  dispatch({
    type: 'HEALTH_SAFETY_WILL_SAVE'
  });

  quiz.saveHealthSafety(formData)
    .then(healthSafety => {
      const {pass, correct_answers: correctAnswers, quiz} = healthSafety;
      if(pass) {
        dispatch({
          type: 'CHECKLIST_ITEM_STATUS_UPDATE',
          payload: {
            key: 'health-safety',
            status: true
          }
        });
        dispatch({
          type: 'HEALTH_SAFETY_QUIZ_PASS',
          payload: pass
        });
      } else {
        dispatch({
          type: 'HEALTH_SAFETY_QUIZ_FAILED',
          payload: correctAnswers
        });
      }
      dispatch({
        type: 'HEALTH_SAFETY_DID_SAVE_SUCCESS',
        payload: quiz
      });
    })
    .catch(err => {
      dispatch({
        type: 'HEALTH_SAFETY_DID_SAVE_FAIL',
        payload: {
          error: 'Error message placeholder'
        }
      });
    });
};

const redoHealthSafetyQuiz = () => dispatch => {
  dispatch({
    type: 'HEALTH_SAFETY_REDO_QUIZ'
  });
};


const getWhmis = () => dispatch => {
  dispatch({
    type: 'WHMIS_WILL_FETCH'
  });
  quiz.getWhmis()
    .then(whmis => {
      dispatch({
        type: 'WHMIS_DID_FETCH',
        payload: whmis
      });
    })
    .catch(err => {
      dispatch({
        type: 'WHMIS_DID_FETCH',
        payload: null
      });
    });
};

const submitWhmisQuiz = formData => dispatch => {
  const validation = quiz.validateWhmis(formData);
  if(!_.isEmpty(validation)) {
    return dispatch({
      type: 'WHMIS_DID_VALIDATION_FAIL',
      payload: validation
    });
  }

  dispatch({
    type: 'WHMIS_WILL_SAVE'
  });

  quiz.saveWhmis(formData)
    .then(whmis => {
      const {pass, correct_answers: correctAnswers, quiz} = whmis;
      if(pass) {
        dispatch({
          type: 'CHECKLIST_ITEM_STATUS_UPDATE',
          payload: {
            key: 'whmis',
            status: true
          }
        });
        dispatch({
          type: 'WHMIS_QUIZ_PASS',
          payload: pass
        });
      } else {
        dispatch({
          type: 'WHMIS_QUIZ_FAILED',
          payload: correctAnswers
        });
      }
      dispatch({
        type: 'WHMIS_DID_SAVE_SUCCESS',
        payload: quiz
      });
    })
    .catch(err => {
      dispatch({
        type: 'WHMIS_DID_SAVE_FAIL',
        payload: {
          error: 'Error message placeholder'
        }
      });
    });
};

const redoWhmisQuiz = () => dispatch => {
  dispatch({
    type: 'WHMIS_REDO_QUIZ'
  });
};

const getManufacturingPolicy = () => dispatch => {
  dispatch({
    type: 'MANUFACTURING_POLICY_WILL_FETCH'
  });

  doc.getOne({type: 'manufacturing-policy'}).then(terms => dispatch({
    type: 'MANUFACTURING_POLICY_DID_FETCH',
    payload: terms
  }));

};


const manufacturingPolicySubmit = (formData) => dispatch => {
  dispatch({
    type: 'MANUFACTURING_POLICY_WILL_SAVE'
  });
  doc.saveManufacturingPolicy({...formData}).then(policy => {
    dispatch({
      type: 'CHECKLIST_ITEM_STATUS_UPDATE',
      payload: {
        key: 'manufacturing-policy',
        status: true,
        deleted: false
      }
    });
    dispatch({
      type: 'MANUFACTURING_POLICY_DID_SAVE_SUCCESS',
      payload: {
        file_exists: true
      }
    });
  }).catch(() => dispatch({
    type: 'MANUFACTURING_POLICY_DID_SAVE_FAIL',
  }));
};

const resetAppStates = () => ({
  type: 'APP_RESET_STATES'
});

export default {
  resetAppStates,
  registerSubmit,
  passwordResetSubmit,
  passwordResetStep2Fetch,
  passwordResetStep2Submit,
  passwordResetResetState,
  loginSubmit,
  applicationSubmit,
  getResume,
  resumeUpload,
  getJobPositionsList,
  getLanguagesList,
  getCitiesList,
  getMyApplication,
  applicationPageLoad,
  getChecklist,
  getTerms,
  termsSubmit,
  taxCreditUpload,
  getTaxCredit,
  getHealthSafety,
  submitHealthSafetyQuiz,
  redoHealthSafetyQuiz,
  getWhmis,
  submitWhmisQuiz,
  redoWhmisQuiz,
  getManufacturingPolicy,
  manufacturingPolicySubmit
};
