import React, { Component } from 'react';
import doc from '../../../services/admin/doc';
import Button from '../../Button';

export default class UnDeleteDocumentButton extends Component {
  state = {
    loading: false
  };

  unRemoveDocument() {
    const {documentId} = this.props;
    this.setState({loading: true});
    doc.update(documentId, {deleted: false})
      .then(() => {
        this.setState({loading: false});
        this.props.onComplete();
      })
      .catch(() => {
        this.setState({loading: false});
        this.props.onComplete();
      });
  }

  render() {
    if(!this.props.documentId) return null;
    return (
      <Button
        className="btn btn-sm btn-success"
        loading={this.state.loading}
        onClick={() => this.unRemoveDocument.call(this)}>Undo Delete</Button>
    );
  }
}
