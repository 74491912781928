import resources from './resources';

export default function initialize() {
  resources.getCitiesList(1)
    .then(cities => {
      localStorage.setItem('cities', JSON.stringify(cities));
    })
    .catch(() => localStorage('cities', ''));
}

export function getCities() {
  const cities = localStorage.getItem('cities');
  return JSON.parse(cities);
}
