import _ from 'lodash';
import { combineReducers } from 'redux';

function checklistReducer(defaultState  = {items: [], allCompleted: false}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'CHECKLIST_WILL_FETCH': {
      state.allCompleted = false;
      state.loading = true;
      break;
    }
    case 'CHECKLIST_DID_FETCH': {
      state.items = payload;
      state.loading = false;
      break;
    }
    case 'CHECKLIST_ITEM_STATUS_UPDATE': {
      const item = _.find(state.items, i => i.key === payload.key);
      item.status = payload.status;
      if('deleted' in payload) {
        item.deleted = payload.deleted;
      }
      break;
    }
    default:
      // Nothing
  }

  let allCompleted = !!state.items.length;
  for(let i = 0; i  < state.items.length; i += 1) {
    if(state.items[i].required === false) continue;
    if(!state.items[i].status) {
      allCompleted = false
      break;
    }
  }
  state.allCompleted = allCompleted;
  return state;
}

const applicationInitialState = {
  values: {},
  loading: false,
  submitLoading: false,
  validationErrors: {}
};
function applicationReducer(defaultState = applicationInitialState, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'APPLICATION_STATE_RESET':
      state = _.cloneDeep(applicationInitialState);
      break;

    case 'MY_APPLICATION_DID_FETCH':
      state.values = payload;
      state.loading = false;
      break;

    case 'MY_APPLICATION_WILL_FETCH':
      state.loading = true;
      break;

    case 'APPLICATION_WILL_SAVE':
      state.submitLoading = true;
      break;

    case 'APPLICATION_DID_SAVE_SUCCESS':
      state.validationErrors = {};
      state.success = true;
      state.submitLoading = false;
      break;

    case 'APPLICATION_DID_VALIDATION_FAIL':
      state.validationErrors = payload;
      state.error = 'Validation errors. Please check your inputs.';
      break;

    case 'APPLICATION_DID_SAVE_FAIL':
      state.error = payload;
      state.validationErrors = {};
      state.submitLoading = false;
      break;

    default:
      // Nothing
  }
  return state;
}

function termsReducer(defaultState = {fetchLoading: false, loading: false}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'TERMS_WILL_FETCH':
      state.file_exists = false;
      state.fetchLoading = true;
      break;

    case 'TERMS_DID_FITCH':
      state.file_exists = payload.file_exists;
      state.fetchLoading = false;
      break;

    case 'TERMS_WILL_SAVE':
      state.loading = true;
      break;

      state.loading = false;
      break;

    case 'TERMS_DID_SAVE_FAIL':
      state.success = false;
      state.loading = false;
      break;

    case 'TERMS_DID_SAVE_SUCCESS':
      state.file_exists = payload.file_exists;
      state.loading = false;
      state.success = true;
      break;
    default:
      // Nothing
  }
  return state;
}

function taxCreditReducer(defaultState = {fetchLoading: false, loading: false}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'TAX_CREDIT_WILL_FETCH':
      state.file_exists = false;
      state.fetchLoading = true;
      break;

    case 'TAX_CREDIT_DID_FETCH':
      state.file_exists = payload.file_exists;
      state.fetchLoading = false;
      break;

    case 'TAX_CREDIT_WILL_UPLOAD':
      state.loading = true;
      break;

    case 'TAX_CREDIT_DID_UPLOAD_SUCCESS':
    case 'TAX_CREDIT_DID_UPLOAD_FAIL':
      state.file_exists = payload.file_exists;
      state.loading = false;
      break;

    default:
      //Nothing..
  }
  return state;
}

function resumeReducer(defaultState = {fetchLoading: false, loading: false}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'RESUME_WILL_FETCH':
      state.fetchLoading = true;
      break;

    case 'RESUME_DID_FETCH':
      state = payload;
      state.fetchLoading = false;
      break;

    case 'RESUME_WILL_UPLOAD':
      state.loading = true;
      break;

    case 'RESUME_DID_UPLOAD_SUCCESS':
    case 'RESUME_DID_UPLOAD_FAIL':
      state = payload;
      state.loading = false;
      break;

    default:
      //Nothing..
  }
  return state;
}

const whmisDefaultState = {fetchLoading: false, loading: false, error: null, result: null, pass: null, validationErrors: {}};
function whmisReducer(defaultState = whmisDefaultState, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'WHMIS_WILL_FETCH':
      state = _.cloneDeep(whmisDefaultState);
      break;
    default:
  }

  switch(type) {
    case 'WHMIS_WILL_FETCH':
      state.fetchLoading = true;
      break;

    case 'WHMIS_DID_FETCH':
      state.fetchLoading = false;
      state.result = payload;
      break;

    case 'WHMIS_DID_VALIDATION_FAIL':
      state.validationErrors = payload;
      state.error = 'Validation errors. Please check your inputs.';
      break;

    case 'WHMIS_REDO_QUIZ':
      state.error = null;
      state.result = null;
      state.validationErrors = {};
      break;

    case 'WHMIS_QUIZ_PASS':
      state.pass = payload;
      state.validationErrors = {};
      state.error = null;
      break;

    case 'WHMIS_QUIZ_FAILED':
      state.error = 'Please correct the mistakes.';
      state.validationErrors = {};
      state.correctAnswers = payload;
      break;

    case 'WHMIS_WILL_SAVE':
      state.loading = true;
      break;

    case 'WHMIS_DID_SAVE_FAIL':
      state.loading = false;
      break;

    case 'WHMIS_DID_SAVE_SUCCESS':
      state.loading = false;
      state.result = payload;
      break;

    default:
      //Nothing
  }
  return state;
}


const healthSafetyDefaultState = {fetchLoading: false, loading: false, error: null, result: null, pass: null, validationErrors: {}};
function healthSafetyReducer(defaultState = healthSafetyDefaultState, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'HEALTH_SAFETY_WILL_FETCH':
      state = _.cloneDeep(healthSafetyDefaultState);
      break;
    default:
  }

  switch(type) {
    case 'HEALTH_SAFETY_WILL_FETCH':
      state.fetchLoading = true;
      break;

    case 'HEALTH_SAFETY_DID_FETCH':
      state.fetchLoading = false;
      state.result = payload;
      break;

    case 'HEALTH_SAFETY_DID_VALIDATION_FAIL':
      state.validationErrors = payload;
      state.error = 'Validation errors. Please check your inputs.';
      break;

    case 'HEALTH_SAFETY_QUIZ_PASS':
      state.pass = payload;
      state.validationErrors = {};
      state.error = null;
      break;

    case 'HEALTH_SAFETY_QUIZ_FAILED':
      state.error = 'Please correct the mistakes.'
      state.correctAnswers = payload;
      state.validationErrors = {};
      break;

    case 'HEALTH_SAFETY_WILL_SAVE':
      state.loading = true;
      break;

    case 'HEALTH_SAFETY_DID_SAVE_SUCCESS':
      state.loading = false;
      state.result = payload;
      break;

    case 'HEALTH_SAFETY_DID_SAVE_FAIL':
      state.loading = false;
      break;

    default:
      //Nothing
  }
  return state;
}

function manufacturingPolicyReducer(defaultState = {fetchLoading: false, loading: false}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'MANUFACTURING_POLICY_WILL_FETCH':
      state.file_exists = false;
      state.fetchLoading = true;
      break;
    case 'MANUFACTURING_POLICY_DID_FETCH':
      state.file_exists = payload.file_exists;
      state.fetchLoading = false;
      break;
    case 'MANUFACTURING_POLICY_WILL_SAVE':
      state.loading = true;
      break;
    case 'MANUFACTURING_POLICY_DID_SAVE_SUCCESS':
      state.file_exists = true;
      state.loading = false;
      break;
    case 'MANUFACTURING_POLICY_DID_SAVE_FAIL':
      state.loading = false;
      break;
    default:
      //Nothing
  }
  return state;
}

function resourcesReducer(defaultState = {}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'JOB_POSITIONS_DID_FETCH':
      state.jobPositions = payload;
      break;

    case 'LANGUAGES_DID_FETCH':
      state.languages = payload;
      break;

    case 'JOB_CITIES_DID_FETCH':
      state.cities = payload;
      break;

    default:
      // Nothing
  }
  return state;
}


export default combineReducers({
  checklist: checklistReducer,
  myApplication: applicationReducer,
  resume: resumeReducer,
  resources: resourcesReducer,
  terms: termsReducer,
  tax: taxCreditReducer,
  quiz: combineReducers({
    healthSafety: healthSafetyReducer,
    whmis: whmisReducer
  }),
  manufacturingPolicy: manufacturingPolicyReducer,
});
