import React from 'react';

const Message = React.forwardRef(({content, type}, ref) => {
  if(!content) return null;
  let className = 'alert-info'
  if(type === 'error') className = 'alert-danger'
  if(type === 'success') className = 'alert-success'
  return (
    <div className={`alert ${className}`} ref={ref}>{content}</div>
  );
});

export default Message;
