import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { workers as action } from '../../actions';

import authentication from '../../services/authentication';

const {superAdmin} = authentication;

const getMenuItems = ({ pathname }) => {
  let items = [{name: 'Checklist', link: '/checklist'}];

  if(superAdmin()) {
    items = [{name: 'Applications', link: '/admin/applications'}];
  }
  return items.map(( item, index ) => {
    const classNames = (( pathname === item.link ) ? 'active ' : '' ) + 'nav-link';
    return(
      <li key={index}><Link to={item.link} className={classNames}>{item.name}</Link></li>
    );
  });
};

function AppHeader({ history, location, resetStates }) {
  const logout = () => {
    authentication.logout().then(() => {
      resetStates();
      const params = {info: 'Good bye.'}
      history.replace(`/login?${qs.stringify(params)}`);
    });
  }
  const profile = authentication.getProfile();

  return(
    <React.Fragment>
      <header className="navbar navbar-expand-lg navbar-dark justify-content-between bg-light bd-navbar">
        <div>
          <Link className="navbar-brand" to="/">App</Link>
        </div>
        <ul className="navbar-nav mr-auto">
          {getMenuItems(location)}
        </ul>
      </header>
      <div className="bottom-bar">
        <div style={{display: 'inline-block', padding: '0 10px', verticalAlign: 'middle'}}>{profile.email}</div>
        <button className="btn btn-outline-dark btn-sm" type="button" onClick={logout}>Logout</button>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    resetStates: () => dispatch(action.resetAppStates())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
