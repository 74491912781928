import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { workers as action } from '../../actions';
import LoadSpinner from '../LoadSpinner';
import Message from '../Message';
import Button from '../Button';
import { nextChecklistItem } from '../../utils';

class Resume extends Component {
  constructor() {
    super(...arguments);
    this.fileUploadRef = React.createRef();
    this.handleClick = this.upload.bind(this);
  }

  componentDidMount() {
    this.props.getResume();
  }

  upload() {
    const file = this.fileUploadRef.current.files[0];
    this.props.upload(file);
  }

  render() {
    const errorMessage = _.get(this.props, 'error', null)
    const output = item => (
      <div className="Resume">
        <h1>Resume</h1>
        <Message content={errorMessage && errorMessage.error} type="error" />
        {item}
      </div>
    );

    return output(
      <div>
        {(() => {
          if(this.props.file_exists) {
            return (
              <React.Fragment>
                <div>You have uploaded your resume.</div>
                <div>
                  <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
                    Continue
                  </Link>
                </div>
              </React.Fragment>
            );
          }

          if(this.props.fetchLoading) {
            return (<LoadSpinner loading={this.props.fetchLoading} />);
          }

          return (
            <React.Fragment>
              <div>
                Upload your resume (Optional)
              </div>
              <input type="file" ref={this.fileUploadRef} />
              <Button onClick={this.handleClick} className="btn btn-primary" loading={this.props.loading}>Upload</Button>
              <div>
                <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
                  Continue
                </Link>
              </div>
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}

function mapStateToProps({workers: {resume, checklist: {items}}}) {
  const {error, file_exists, loading, fetchLoading} = resume;
  return {
    error,
    file_exists,
    loading,
    fetchLoading,
    checklistItems: items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResume: () => dispatch(action.getResume()),
    upload: (formData) => dispatch(action.resumeUpload(formData))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
