import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';

export default class SearchForm extends Component {
  inputRefs = {
    firstName: React.createRef(),
    lastName: React.createRef(),
    gender: React.createRef(),
    postalCode: React.createRef()
  }
  constructor() {
    super(...arguments);
    this.handleSearchClick = () => {
      this.props.onSearchClick(this.value);
    }
  }

  get value() {
    return {
      first_name: this.inputRefs.firstName.current.value,
      last_name: this.inputRefs.lastName.current.value,
      gender: this.inputRefs.gender.current.value,
      postal_code: this.inputRefs.postalCode.current.value
    };
  }
  render() {
    const {defaultValues} = this.props;
    return (
      <form onSubmit={e => {e.preventDefault();}}>
        <div className="form-row form-group">
          <div className="col-md-3">
            <input
              id="firstName"
              placeholder="First Name"
              ref={this.inputRefs.firstName}
              className="form-control"
              defaultValue={defaultValues.first_name} />
          </div>
          <div className="col-md-3">
            <input
              id="lastName"
              placeholder="Last Name"
              ref={this.inputRefs.lastName}
              className="form-control"
              defaultValue={defaultValues.last_name} />
          </div>
          <div className="col-md-2">
            <select id="gender" ref={this.inputRefs.gender} className="form-control" defaultValue={defaultValues.gender}>
              <option value="">Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
          <div className="col-md-2">
            <input
              id="postalCode"
              placeholder="Postal Code"
              ref={this.inputRefs.postalCode}
              className="form-control"
              defaultValue={defaultValues.postal_code} />
          </div>
          <div className="col-md-2">
            <Button onClick={this.handleSearchClick} className="btn btn-primary" loading={this.props.loading}>Search</Button>
          </div>
        </div>
      </form>
    );
  }
}

SearchForm.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
