import React, { Component } from 'react';
import doc from '../../../services/admin/doc';
import Button from '../../Button';
import Message from '../../Message';

export default class UploadZone extends Component {
  state = {
    loading: false,
    error: null,
    description: null
  };

  constructor() {
    super(...arguments);
    this.inputRefs = {
      file: React.createRef(),
      type: React.createRef(),
      description: React.createRef()
    };
    this.handleClick = this.upload.bind(this);
  }

  upload() {
    const file = this.inputRefs.file.current.files[0];
    const {type} = this.state;
    const description = this.inputRefs.description.current.value;
    this.setState({loading: true});
    doc.upload(this.props.userId, file, type, {description})
      .then(() => {
        this.setState({loading: false, error: null});
        this.props.onComplete();
        this.inputRefs.file.current.value = '';
        this.inputRefs.type.current.value = '';
        this.inputRefs.description.current.value = '';
      })
      .catch(({error}) => this.setState({loading: false, error}));
  }

  render() {
    const typeDisplay = (this.state.type === 'other') ? 'block' : 'none';
    return (
      <div className="UploadZone">
        <h5>File Upload</h5>
        <Message content={this.state.error} type="error" />
        <form>
          <div className="form-row form-group">
            <div className="col-md-6">
              <input type="file" className="form-control" ref={this.inputRefs.file} />
            </div>
            <div className="col-md-6">
              <select className="form-control" onChange={({target: {value}}) => this.setState({type: value})}>
                <option value="">Select type</option>
                <option value="terms">Terms</option>
                <option value="tax-credit">Tax credit</option>
                <option value="manufacturing-policy">GMP</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md=4" style={{display: typeDisplay}}>
              <input
                className="form-control"
                placeholder="Description"
                ref={this.inputRefs.description} />
            </div>
            <div className="col-md=4">
              <Button onClick={this.handleClick} className="btn btn-primary" loading={this.state.loading}>Upload</Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
