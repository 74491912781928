import React from 'react';
import moment from 'moment';

export default function Time({className, timestamp, format}) {
  let time = moment(timestamp);
  const now = moment();
  if(format === 'relative' && now.diff(time, 'days') < 3) {
    time = time.fromNow();
  } else {
    time = time.format('MM-DD-YYYY');
  }

  return(
    <div className={className}>{time}</div>
  );
}
