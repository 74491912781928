import React from 'react';

const renderOptions = (options = [], valueProp, labelProp) => (
  options.map((opt, index) => {
    return (<option value={opt[valueProp]} key={index}>{opt[labelProp]}</option>);
  })
);

export default ({ items = [], valueProp, labelProp, id, name: inputName, className, disabled = false, selected = '', onChange }) => {
  if(!inputName) return null;
  const options = [].concat(items);
  const placeHolder = {};
  placeHolder[valueProp] = null;
  placeHolder[labelProp] = 'Select..';
  options.unshift(placeHolder);

  return(
    <select id={id} className={className} name={inputName} disabled={!!disabled} value={selected} onChange={onChange}>
      {renderOptions(options, valueProp, labelProp)}
    </select>
  );
};

