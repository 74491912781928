import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import qs from 'qs';
import config from '../../config';
import './style.scss';
import { workers as action } from '../../actions';
import Button from '../Button';

class Register extends Component {
  state = {
    error: null,
    recaptchaSuccess: (config.features.recaptcha) ? false : true
  };
  constructor() {
    super(...arguments);
    this.emailInputRef = React.createRef();
    this.passwordInputRef = React.createRef();
    this.rePasswordInputRef = React.createRef();
    this.registerHandler = this.registerHandler.bind(this);
  }

  registerHandler(e) {
    e.preventDefault();
    const email = this.emailInputRef.current.value.trim();
    const password = this.passwordInputRef.current.value.trim();
    const rePassword = this.rePasswordInputRef.current.value.trim();

    if(password !== rePassword) {
      this.setState({error: 'Password did not match. Re-enter password.'});
      return;
    }
    this.props.registerSubmit({email, password});
  }

  componentWillReceiveProps({register, history}) {
    if(register.success) {
      const message = encodeURI('Registration success. Please login.');
      history.push(`/login?info=${message}`);
    }
  }

  render() {
    const renderErrorMessage = () => {
      const { register } = this.props;
      const { error } = this.state;
      const _error = (error) ? error : (register && register.error);

      if(!_error) return null;
      return(
        <div className="alert-danger alert">{_error}</div>
      );
    }
    const renderInfoMessage = () => {
      let { info } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
      if(!info || !info.length) return null;
      return(
        <div className="alert alert-info">{info}</div>
      )
    }
    return(
      <div className="Register">
        <h1>Register</h1>
        {renderErrorMessage()}
        {renderInfoMessage()}
        <form action="" onSubmit={this.registerHandler}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" type="email" className="form-control" ref={this.emailInputRef} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input id="password" type="password" className="form-control" ref={this.passwordInputRef} />
          </div>
          <div className="form-group">
            <label htmlFor="re_password">Retype Password</label>
            <input id="re_password" type="password" className="form-control" ref={this.rePasswordInputRef} />
          </div>
          {(() => {
            if(!config.features.recaptcha) return null;
            return (
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  onChange={res => this.setState({recaptchaSuccess: !!res})} />
              </div>
            );
          })()}
          <div>
            <Button
              className="btn btn-primary btn-lg btn-submit"
              disabled={!this.state.recaptchaSuccess}
              loading={this.props.loading}
              onClick={this.registerHandler}>Register</Button>
            <Link to="/login" className="btn btn-link" style={{marginTop: 18}}>Login</Link>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps({register}) {
  return {register, loading: register.loading};
}

function mapDispatchToProps(dispatch) {
  return {
    registerSubmit: (formData) => dispatch(action.registerSubmit(formData))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
