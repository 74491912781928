import React, { Component } from 'react';

export default class Modal extends Component {
  state = {
    display: 'none',
    show: false
  };

  componentWillReceiveProps({show}) {
    if(show) {
      let display = (show) ? 'block' : 'none';
      this.setState({display}, () => {
        window.document.body.classList.add('modal-open');
        setTimeout(() => this.setState({show}), 1);
      });
    } else {
      let display = (show) ? 'block' : 'none';
      this.setState({show}, () => {
        window.document.body.classList.remove('modal-open');
        setTimeout(() => this.setState({display}), 130);
      });
    }
  }

  getBackdrop() {
    const {show, display} = this.state;
    const classNames = (show) ? ['show'] : [];
    return (
      <div className={`fade modal-backdrop in ${classNames.join(' ')}`} style={{display}}></div>
    );
  }

  getFooter() {
    if(!this.props.footer) return null;
    return (
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.onClose}>Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    );
  }

  getModal() {
    const {show, display} = this.state;
    const {size} = this.props;
    const classNames = (show) ? ['show'] : [];
    const sizeClass = (size === 'large') ? 'modal-lg' : (size === 'small') ? 'modal-sm' : '';

    return (
      <div className={`modal fade ${classNames.join(' ')}`} tabIndex="-1" style={{display}} onClick={e => {
        if(e.target.classList.contains('modal')) {
          this.props.onClose();
        }
      }}>
        <div className={`modal-dialog ${sizeClass}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
            {this.getFooter.call(this)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.getBackdrop(this)}
        {this.getModal()}
      </React.Fragment>
    );

  }
}
