import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import './style.scss';
import { workers as action } from '../../actions';
import authentication from '../../services/authentication';
import Button from '../Button';

const {superAdmin} = authentication;

class Login extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      error: null
    };
    this.emailInputRef = React.createRef();
    this.passwordInputRef = React.createRef();
    this.loginHandler = this.loginHandler.bind(this);
  }

  componentWillReceiveProps({success, history}) {
    if(!!success) {
      if(superAdmin()) {
        history.replace('/admin');
      } else {
        history.replace('/checklist');
      }
    }
  }

  loginHandler(e) {
    e.preventDefault();
    const email = this.emailInputRef.current.value.trim();
    const password = this.passwordInputRef.current.value.trim();
    this.props.submit(email, password);
  }

  render() {
    const renderErrorMessage = () => {
      const { error } = this.props;
      if(!error) return null;
      return(
        <div className="alert-danger alert">{error}</div>
      );
    }
    const renderInfoMessage = () => {
      const { info } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
      if(!info || !info.length) return null;
      return(
        <div className="alert alert-info">{info}</div>
      )
    }
    return(
      <div className="Login">
        <h1>Login</h1>
        {renderErrorMessage()}
        {renderInfoMessage()}
        <form action="" onSubmit={this.loginHandler}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" type="email" className="form-control" ref={this.emailInputRef} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input id="password" type="password" className="form-control" ref={this.passwordInputRef} />
          </div>
          <div>
            <Button
              className="btn btn-primary btn-lg btn-submit"
              loading={this.props.loading}
              onClick={this.loginHandler}>Login</Button>
            <Link to="/register" className="btn btn-link" style={{marginTop: 18}}>Register</Link>
            <Link to="/password-reset-request" className="btn btn-link" style={{marginTop: 18}}>Reset password</Link>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps({login: {success, error, loading}}) {
  return {success, error, loading};
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (email, password) => dispatch(action.loginSubmit(email, password))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
