import React, { Component } from 'react';
import RadioGroup from '../input/RadioGroup';
import Button from '../Button';

import imageq6 from '../../images/flame_over_circle.gif';
import imageq7 from '../../images/gas_cylinder.gif';
import imageq8 from '../../images/corrosion.gif';
import imageq9 from '../../images/exploding_bomb.gif';
import imageq10 from '../../images/flame.gif';
import imageq11 from '../../images/health_hazard.gif';
import imageq12 from '../../images/exclamation_mark.gif';
import imageq13 from '../../images/skull_and_crossbones.gif';
import imageq14 from '../../images/biohazardous_infectious_materials.gif';

const validate = (field, validationErrors, classNames = '') => {
  const {error, message} = validationErrors[field] || {};
  if(error) return {className: 'is-invalid ' + classNames, message};
  return {className: classNames};
}

export default class WhmisQuiz extends Component {
  state = {
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
    q6: null,
    q7: null,
    q8: null,
    q9: null,
    q10: null,
    q11: null,
    q12: null,
    q13: null,
    q14: null
  };

  constructor() {
    super(...arguments);
    this.handleSubmit = (e) => {
      e.preventDefault();
      this.props.onSubmit(this.state);
    }
  }

  render() {
    const {validationErrors, correctAnswers = {}} = this.props;
    const getItems = (key, items) => {
      items.forEach(item => {
        const answer = this.state[key];
        const correctAnswer = correctAnswers[key];
        if(!correctAnswer || answer === correctAnswer) return;
        item.className = 'wrong-answer';
      })
      return items;
    };
    return (
      <form>
        <p>Please answer all questions to the best of your knowledge. Should you have any questions regarding this
          test please contact OLA Staffing at 416-743-9675</p>
        <p><strong>Instructions</strong></p>

        <div className="questions">
          <p>1. What does W.H.M.I.S. stand for?</p>
          <RadioGroup
            name="q1"
            className={validate('q1', validationErrors).className}
            items={getItems('q1', [
              {value: 'a', label: 'Why Health matters is Serious'},
              {value: 'b', label: 'Workplace Hazardous Materials Information System'},
              {value: 'c', label: 'Work Health Ministry Information System'}
            ])}
            onChange={q1 => this.setState({q1})}
            selected={this.state.q1} />

          <p>2. What does GHS stand for?</p>
          <RadioGroup
            name="q2"
            className={validate('q2', validationErrors).className}
            items={getItems('q2', [
              {value: 'a', label: 'General Hospital Systems'},
              {value: 'b', label: 'Good Health and Safety'},
              {value: 'c', label: 'Globally Harmonized System'}
            ])}
            onChange={q2 => this.setState({q2})}
            selected={this.state.q2} />

          <p>3. What are three parts of WHMIS?</p>
          <RadioGroup
            name="q3"
            className={validate('q3', validationErrors).className}
            items={getItems('q3', [
              {value: 'a', label: 'Labels, SDS, Worker Education'},
              {value: 'b', label: 'Poison, Labels, SDS'},
              {value: 'c', label: 'Worker Education, Poison, SDS'}
            ])}
            onChange={q3 => this.setState({q3})}
            selected={this.state.q3} />

          <p>4. Safety Data Sheets contain information such as?</p>
          <RadioGroup
            name="q4"
            className={validate('q4', validationErrors).className}
            items={getItems('q4', [
              {value: 'a', label: 'First aid measures'},
              {value: 'b', label: 'How to lift and carry the container'},
              {value: 'c', label: 'Safe handling and storage information'},
              {value: 'd', label: 'Both A and C'}
            ])}
            onChange={q4 => this.setState({q4})}
            selected={this.state.q4} />

          <p>5. Why is WHMIS 2015 training important?</p>
          <RadioGroup
            name="q5"
            className={validate('q5', validationErrors).className}
            items={getItems('q5', [
              {value: 'a', label: 'To get your work done faster'},
              {value: 'b', label: 'To have information to protect your health and safety'},
              {value: 'c', label: 'To serve your customers better'}
            ])}
            onChange={q5 => this.setState({q5})}
            selected={this.state.q5} />

          <div className="picture-questions">
            <div>
              6. This symbol is:<br />
              <img src={imageq6} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q6"
                className={validate('q6', validationErrors).className}
                items={getItems('q6', [
                  {value: 'a', label: 'Flame Over Circle (Oxidizing hazards)'},
                  {value: 'b', label: 'Gas Cylinder (Gases under pressure)'},
                  {value: 'c', label: 'Flame (Fire Hazards)'}
                ])}
                onChange={q6 => this.setState({q6})}
                selected={this.state.q6} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              7. This symbol is:<br />
              <img src={imageq7} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q7"
                className={validate('q7', validationErrors).className}
                items={getItems('q7', [
                  {value: 'a', label: 'Exploding Bomb (Explosion or reactivity hazards)'},
                  {value: 'b', label: 'Gas Cylinder (Gases under pressure)'},
                  {value: 'c', label: 'Exclamation Mark (May cause immediate health effects)'}
                ])}
                onChange={q7 => this.setState({q7})}
                selected={this.state.q7} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              8. This symbol is:<br />
              <img src={imageq8} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q8"
                className={validate('q8', validationErrors).className}
                items={getItems('q8', [
                  {value: 'a', label: 'Corrosion (Corrosive damage to metals, clothing, skin, etc.)'},
                  {value: 'b', label: 'Biohazardous Infectious Materials (Organisms/Toxins causing disease)'},
                  {value: 'c', label: 'Gas Cylinder (Gases under pressure)'}
                ])}
                onChange={q8 => this.setState({q8})}
                selected={this.state.q8} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              9. This symbol is:<br />
              <img src={imageq9} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q9"
                className={validate('q9', validationErrors).className}
                items={getItems('q9', [
                  {value: 'a', label: 'Flame (Fire Hazards)'},
                  {value: 'b', label: 'Gas Cylinder (Gases under pressure)'},
                  {value: 'c', label: 'Exploding Bomb (Explosion or reactivity hazards)'}
                ])}
                onChange={q9 => this.setState({q9})}
                selected={this.state.q9} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              10. This symbol is:<br />
              <img src={imageq10} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q10"
                className={validate('q10', validationErrors).className}
                items={getItems('q10', [
                  {value: 'a', label: 'Flame (Fire Hazards)'},
                  {value: 'b', label: 'Exploding Bomb (Explosion or reactivity hazards)'},
                  {value: 'c', label: 'Flame Over Circle (Oxidizing hazards)'}
                ])}
                onChange={q10 => this.setState({q10})}
                selected={this.state.q10} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              11. This symbol is:<br />
              <img src={imageq11} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q11"
                className={validate('q11', validationErrors).className}
                items={getItems('q11', [
                  {value: 'a', label: 'Biohazardous Infectious Materials (Organisms/Toxins causing disease)'},
                  {value: 'b', label: 'Health Hazard (May cause serious health effects)'},
                  {value: 'c', label: 'Corrosion (Corrosive damage to metals, clothing, skin, etc.)'}
                ])}
                onChange={q11 => this.setState({q11})}
                selected={this.state.q11} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              12. This symbol is:<br />
              <img src={imageq12} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q12"
                className={validate('q12', validationErrors).className}
                items={getItems('q12', [
                  {value: 'a', label: 'Gas Cylinder (Gases under pressure)'},
                  {value: 'b', label: 'Health Hazard (May cause serious health effects)'},
                  {value: 'c', label: 'Exclamation Mark (May cause immediate health effects)'}
                ])}
                onChange={q12 => this.setState({q12})}
                selected={this.state.q12} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              13. This symbol is:<br />
              <img src={imageq13} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q13"
                className={validate('q13', validationErrors).className}
                items={getItems('q13', [
                  {value: 'a', label: 'Skull and Crossbones (Poisonous, Immediate and possibly serious health effects)'},
                  {value: 'b', label: 'Corrosion (Corrosive damage to metals, clothing, skin, etc.)'},
                  {value: 'c', label: 'Health Hazard (May cause serious health effects)'}
                ])}
                onChange={q13 => this.setState({q13})}
                selected={this.state.q13} />
            </div>
          </div>

          <div className="picture-questions">
            <div>
              14. This symbol is:<br />
              <img src={imageq14} alt="" />
            </div>
            <div>
              <RadioGroup
                name="q14"
                className={validate('q14', validationErrors).className}
                items={getItems('q14', [
                  {value: 'a', label: 'Flame (Fire Hazards)'},
                  {value: 'b', label: 'Biohazardous Infectious Materials (Organisms/Toxins causing disease)'},
                  {value: 'c', label: 'Corrosion (Corrosive damage to metals, clothing, skin, etc.)'}
                ])}
                onChange={q14 => this.setState({q14})}
                selected={this.state.q14} />
            </div>
          </div>

        </div>
        <Button className="btn btn-primary btn-submit btn-lg" loading={this.props.loading} onClick={this.handleSubmit}>Submit</Button>
      </form>
    );
  }
}
