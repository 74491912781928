import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { workers as action } from '../../actions';
import LoadSpinner from '../LoadSpinner';
import Progressbar from '../Progressbar';
import Message from '../Message';
import { focusElement, nextChecklistItem } from '../../utils';
import WhmisQuiz from './whmisQuiz';

class Whmis extends Component {
  constructor() {
    super(...arguments);
    this.messageBoxRef = React.createRef();
  }

  componentDidMount() {
    this.props.getWhmis();
  }

  componentWillReceiveProps({error}) {
    if(error) {
      setTimeout(() => {
        focusElement(this.messageBoxRef.current);
      }, 10);
    }
  }

  render() {
    let {error, pass, result} = this.props;
    if(pass === true || (result && result.score === 100)) {
      return (
        <div className="Quiz Whmis">
          <Progressbar />
          <h1>WHMIS 2015 Testing</h1>
          <div>Congratulations. You have passed.</div>
          <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
            Continue
          </Link>
        </div>
      );
    }
    if(this.props.fetchLoading) {
      return (
        <div className="Quiz Whmis">
          <Progressbar />
          <h1>WHMIS 2015 Testing</h1>
          <LoadSpinner loading={this.props.fetchLoading} />
        </div>
      );
    }
    return (
      <div className="Quiz Whmis">
        <Progressbar />
        <h1>WHMIS 2015 Testing</h1>
        {(() => {
          return (
            <React.Fragment>
              <Message content={this.props.error} ref={this.messageBoxRef} type="error" />
              <WhmisQuiz
                loading={this.props.loading}
                correctAnswers={this.props.correctAnswers}
                validationErrors={this.props.validationErrors}
                onSubmit={formData => this.props.submit(formData)} />
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}

function mapStateToProps({workers: {quiz: {whmis: state}, checklist: {items}}}) {
  return {
    loading: state.loading,
    fetchLoading: state.fetchLoading,
    result: state.result,
    checklistItems: items,
    validationErrors: state.validationErrors,
    error: state.error,
    pass: state.pass,
    correctAnswers: state.correctAnswers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWhmis: () => dispatch(action.getWhmis()),
    submit: (formData) => dispatch(action.submitWhmisQuiz(formData)),
    redoQuiz: () => dispatch(action.redoWhmisQuiz())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Whmis);
