import React from 'react';
import PropTypes from 'prop-types';

export default function Pagination({total, limit, currentPage, onPageClick}) {
  const totalPages = Math.ceil(total / limit);
  const range = ((totalPages - currentPage) > 4) ? 4 : totalPages - currentPage;
  let pageItems = [];
  let startsAt = currentPage;
  let upto = startsAt + range;
  if(upto >= totalPages) {
    startsAt = totalPages - range;
    upto = totalPages;
  }
  for(let page = startsAt; page <= upto; page += 1) {
    const activeClass = currentPage === page ? 'active' : '';
    pageItems.push(
      <li className={`page-item ${activeClass}`} key={page}><button className="page-link" onClick={() => onPageClick(page)}>{page}</button></li>
    );
  }

  const firstPageLink = (currentPage === 1) ?
    (<React.Fragment>
      <li className="page-item disabled"><button disabled={true} className="page-link">First</button></li>
      <li className="page-item disabled"><button disabled={true} className="page-link">Previous</button></li>
    </React.Fragment>):
    (<React.Fragment>
      <li className="page-item"><button className="page-link" onClick={() => onPageClick(1)}>First</button></li>
      <li className="page-item"><button className="page-link" onClick={() => onPageClick(currentPage -1)}>Previous</button></li>
    </React.Fragment>);

  const lastPageLink = (currentPage === totalPages) ?
    (<React.Fragment>
      <li className="page-item disabled"><button disabled={true} className="page-link">Next</button></li>
      <li className="page-item disabled"><button disabled={true} className="page-link">Last</button></li>
    </React.Fragment>):
    (<React.Fragment>
      <li className="page-item"><button className="page-link" onClick={() => onPageClick(currentPage +1)}>Next</button></li>
      <li className="page-item"><button className="page-link" onClick={() => onPageClick(totalPages)}>Last</button></li>
    </React.Fragment>);

  return(
    <nav aria-label="Page navigation" style={{marginTop: '1em'}}>
      <ul className="pagination">
        <li className="page-item disabled"><button  className="page-link" disabled={true}>Total {total.toLocaleString()}</button></li>
        {firstPageLink}
        {pageItems}
        {lastPageLink}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  onPageClick: PropTypes.func
};
