import axios from 'axios';
import _ from 'lodash';
import config from '../../config';
import { getCities } from '../preload';

const applications = {
  async paginate(page, filters = {}) {

    let response = null;
    const cities = getCities();
    const limit = 20;
    const offset = (page * limit) - limit;
    const params = Object.assign({}, {...filters}, {offset, limit});
    try {
      const {data, total, limit} = await axios.get(`${ config.API.URL }admin/applications/`, {params} );
      data.forEach(application => {
        const city  = _.find(cities, c => c.City_Id === application.city);
        application.city = (city || {}).City_Name;
      });
      response = {data, total, limit};
    } catch(err) {
      throw err;
    }
    return response;
  }
};

export default applications;
