import React, { Component } from 'react';
import SignaturePad from 'signature_pad';
import './Signature.scss';

const styles = {
  border: '1px solid #ccc'
}
export default class Signature extends Component {
  constructor() {
    super(...arguments);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.pad = new SignaturePad(this.canvas.current, {
      maxWidth: 1,
      backgroundColor: 'white'
    });
  }

  get current() {
    return this.pad;
  }

  render() {
    return (
      <div className="Signature">
        <canvas ref={this.canvas} style={styles}></canvas>
      </div>
    );
  }
};
