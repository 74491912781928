import _ from 'lodash';
import { combineReducers } from 'redux';

import adminReducers from './admin';
import workersReducers from './workers';

function registerReducer(defaultState = {}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'REGISTER_WILL_SUBMIT':
      state = {
        loading: true,
        success: false,
        error: null
      };
      break;

    case 'REGISTER_DID_SUCCESS':
      state = {
        success: true,
        loading: false
      };
      break;

    case 'REGISTER_DID_FAIL':
      state = {
        error: payload.error,
        loading: false
      };
      break;
    default:
      // Nothing
  }
  return state;
}

function passwordResetReducer(defaultState = {}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'PASSWORD_RESET_WILL_SUBMIT':
      state = {
        loading: true,
        success: false,
        error: null
      };
      break;

    case 'PASSWORD_RESET_DID_SUCCESS':
      state = {
        success: true,
        loading: false
      };
      break;

    case 'PASSWORD_RESET_DID_FAIL':
      state = {
        error: payload.error,
        loading: false
      };
      break;

    case 'PASSWORD_RESET_STEP2_FETCH':
      state = {
        validToken: payload,
        loading: false
      };
      break;

    case 'PASSWORD_RESET_STEP2_WILL_SUBMIT':
      state = {
        loading: true,
      };
      break;

    case 'PASSWORD_RESET_STEP2_DID_SUCCESS':
      state = {
        success: true,
        loading: false,
      };
      break;

    case 'PASSWORD_RESET_STEP2_DID_FAIL':
      state = {
        success: false,
        error: 'Unable to reset password.',
        loading: false,
      };
      break;

    case 'PASSWORD_RESET_RESET_STATE':
      state = {
        success: null,
        error: null,
        loading: null
      };
      break;

    default:
      // Nothing
  }
  return state;
}

function authReducer(defaultState  = {}, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'LOGIN_WILL_SUBMIT':
      state = {
        loading: true,
        error: null,
        success: false
      };
      break;

    case 'LOGIN_DID_SUCCESS':
      state = {
        success: true,
        error: null,
        loading: false
      };
      break;

    case 'LOGIN_DID_FAIL':
      state = {
        success: false,
        error: payload.error,
        loading: false
      };
      break;
    default:
      // Nothing
  }
  return state;
}

const appReducers = combineReducers({
  admin: adminReducers,
  workers: workersReducers,
  register: registerReducer,
  passwordReset: passwordResetReducer,
  login: authReducer
});

export default function(state, action) {
  if(action.type === 'APP_RESET_STATES') {
    state = undefined;
  }
  return appReducers(state, action);
};
