import React, { Component } from 'react';
import RadioGroup from '../input/RadioGroup';
import Button from '../Button';

const validate = (field, validationErrors, classNames = '') => {
  const {error, message} = validationErrors[field] || {};
  if(error) return {className: 'is-invalid ' + classNames, message};
  return {className: classNames};
}

export default class HealthSafetyQuiz extends Component {
  state = {
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
    q6: null,
    q7: null,
    q8: null,
    q9: null,
    q10: null,
    q11: null,
    q12: null,
    q13: null,
    q14: null,
    q15: null,
    q16: null,
    q17: null,
    q18: null,
    q19: null
  };

  constructor() {
    super(...arguments);
    this.handleSubmit = (e) => {
      e.preventDefault();
      this.props.onSubmit(this.state);
    }
  }

  render() {
    const {validationErrors, correctAnswers = {}} = this.props;
    const getItems = key => {
      const items = [
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'}
      ];
      const answer = this.state[key];
      const correctAnswer = correctAnswers[key];
      if(answer !== correctAnswer) {
        if(correctAnswer === 'yes') items[1].className = 'wrong-answer';
        if(correctAnswer === 'no') items[0].className = 'wrong-answer';
      }
      return items;
    };
    return (
      <form>
        <p>As per requirements to all workplaces covered under the Occupation Health and Safety Act, OLA Staffing requires that
          all of our workers complete the one time, basic Health and Safety Awareness training program developed by the
          Ontario Ministry of Labour. This training program will increase your knowledge of basic rights and responsibilities

          under the OHSA and heighten awareness of basic workplace health and safety issues.</p>
        <p><strong>Instructions</strong></p>
        <p>Using the provided Health and Safety at Work, Worker Health and Safety Awareness in 4 Steps and assistance from
          your OLA Staffing Recruiter/Trainer, please read through the Workbook and answer the following questions to the

          best of your ability. A recruiter will review this training and testing with you once complete.</p>

        <div className="questions">
          <h5>Step #1: Get On Board:</h5>
          <p>The number of people in Ontario who suffer a work-related illness or injury each year would fill the seats of a
            dozen big hockey arenas.</p>
          <RadioGroup
            name="q1"
            className={validate('q1', validationErrors).className}
            items={getItems('q1')}
            onChange={q1 => this.setState({q1})}
            selected={this.state.q1} />

          <p>New workers are less likely to get hurt on the job than people who have been on the job longer.</p>
          <RadioGroup
            name="q2"
            className={validate('q2', validationErrors).className}
            items={getItems('q2')}
            onChange={q2 => this.setState({q2})}
            selected={this.state.q2} />

          <p>The Occupational Health and Safety Act and Regulations tell everyone from the employer to the newest
            worker how to make the workplace safer.</p>
          <RadioGroup
            name="q3"
            className={validate('q3', validationErrors).className}
            items={getItems('q3')}
            onChange={q3 => this.setState({q3})}
            selected={this.state.q3} />

          <p>The OHSA puts the greatest responsibility on the employer to make sure no one gets hurt or sick on the job.</p>
          <RadioGroup
            name="q4"
            className={validate('q4', validationErrors).className}
            items={getItems('q4')}
            onChange={q4 => this.setState({q4})}
            selected={this.state.q4} />

          <p>It’s against the law for my employer to fire or punish me for doing what the OHSA says.</p>
          <RadioGroup
            name="q5"
            className={validate('q5', validationErrors).className}
            items={getItems('q5')}
            onChange={q5 => this.setState({q5})}
            selected={this.state.q5} />

          <h5>Step #2: Get In The Know:</h5>
          <p>The OHSA says that you have the right to know about hazards in your workplace.</p>
          <RadioGroup
            name="q6"
            className={validate('q6', validationErrors).className}
            items={getItems('q6')}
            onChange={q6 => this.setState({q6})}
            selected={this.state.q6} />

          <p>If a hazard can make you sick, you will always start to feel sick right away.</p>
          <RadioGroup
            name="q7"
            className={validate('q7', validationErrors).className}
            items={getItems('q7')}
            onChange={q7 => this.setState({q7})}
            selected={this.state.q7} />

          <p>To keep from getting hurt on the job, you need to find out about the hazards while you’re working.</p>
          <RadioGroup
            name="q8"
            className={validate('q8', validationErrors).className}
            items={getItems('q8')}
            onChange={q8 => this.setState({q8})}
            selected={this.state.q8} />

          <p>If you have any doubts about the safety of the work you’re doing, you should keep those doubts to yourself.</p>
          <RadioGroup
            name="q9"
            className={validate('q9', validationErrors).className}
            items={getItems('q9')}
            onChange={q9 => this.setState({q9})}
            selected={this.state.q9} />

          <p>If you see a hazard while you’re working, you should report it to your supervisor or employer right away.</p>
          <RadioGroup
            name="q10"
            className={validate('q10', validationErrors).className}
            items={getItems('q10')}
            onChange={q10 => this.setState({q10})}
            selected={this.state.q10} />

          <h5>Step #3: Get Involved:</h5>
          <p>It’s important that you know the safe way to do your job. You should share what you know. If you see a
          hazard, you must report it to your supervisor or employer.</p>
          <RadioGroup
            name="q11"
            className={validate('q11', validationErrors).className}
            items={getItems('q11')}
            onChange={q11 => this.setState({q11})}
            selected={this.state.q11} />

          <p>You should get involved in health and safety by asking questions, and you should put what you learn from
          training into practice on the job.</p>
          <RadioGroup
            name="q12"
            className={validate('q12', validationErrors).className}
            items={getItems('q12')}
            onChange={q12 => this.setState({q12})}
            selected={this.state.q12} />

          <p>If you can’t find any health and safety information posted in your workplace, you should just go back to work
          and not worry about it.</p>
          <RadioGroup
            name="q13"
            className={validate('q13', validationErrors).className}
            items={getItems('q13')}
            onChange={q13 => this.setState({q13})}
            selected={this.state.q13} />

          <p>Your health and safety representative or joint health and safety committee can help you with any concerns that
          you might have about working safely.</p>
          <RadioGroup
            name="q14"
            className={validate('q14', validationErrors).className}
            items={getItems('q14')}
            onChange={q14 => this.setState({q14})}
            selected={this.state.q14} />

          <p>It’s OK to take safety shortcuts to get the work done faster and on time.</p>
          <RadioGroup
            name="q15"
            className={validate('q15', validationErrors).className}
            items={getItems('q15')}
            onChange={q15 => this.setState({q15})}
            selected={this.state.q15} />

          <h5>Step #4: Get More Help:</h5>
          <p>If you report a dangerous situation to your supervisor and your health and safety representative and they can’t
          find a way to fix it, you can call the Ministry of Labour’s toll-free number for help.</p>
          <RadioGroup
            name="q16"
            className={validate('q16', validationErrors).className}
            items={getItems('q16')}
            onChange={q16 => this.setState({q16})}
            selected={this.state.q16} />

          <p>If you have reason to believe the equipment you are using might hurt you or someone you work with, you have
          the legal right to refuse the work.</p>
          <RadioGroup
            name="q17"
            className={validate('q17', validationErrors).className}
            items={getItems('q17')}
            onChange={q17 => this.setState({q17})}
            selected={this.state.q17} />

          <p>Some workers, such as nurses, firefighters and police officers, have a limited right to refuse work.</p>
          <RadioGroup
            name="q18"
            className={validate('q18', validationErrors).className}
            items={getItems('q18')}
            onChange={q18 => this.setState({q18})}
            selected={this.state.q18} />

          <p>It’s against the law for your employer or your supervisor to fire or punish you for doing what the OHSA
          expects you to do, or for asking them to do what the OHSA expects them to do.</p>
          <RadioGroup
            name="q19"
            className={validate('q19', validationErrors).className}
            items={getItems('q19')}
            onChange={q19 => this.setState({q19})}
            selected={this.state.q19} />

        </div>
        <Button className="btn btn-primary btn-submit btn-lg" loading={this.props.loading} onClick={this.handleSubmit}>Submit</Button>
      </form>
    );
  }
}
