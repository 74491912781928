import React, { Component } from 'react';
import { connect } from 'react-redux';
import { workers as action } from '../../actions';
import './style.scss';
import Form from './form';
import LoadSpinner from '../LoadSpinner';
import Progressbar from '../Progressbar';
import Message from '../Message';
import { focusElement, nextChecklistItem } from '../../utils';

class Application extends Component {
  constructor() {
    super(...arguments);
    this.submitForm = this.submitForm.bind(this);
    this.messageBoxRef = React.createRef();
  }

  componentDidMount() {
    this.props.getJobPositionsList();
    this.props.getLanguagesList();
    this.props.resetStates();
    this.props.getMyApplication();
  }

  componentWillReceiveProps({myApplication, history, checklistItems}) {
    const { success, error } = myApplication || {};
    if(success) {
      window.scrollTo(0, 0);
      history.push(nextChecklistItem(checklistItems) || '/checklist');
    }
    if(error) {
      setTimeout(() => {
        focusElement(this.messageBoxRef.current);
      }, 10);
    }
  }

  submitForm(values) {
    this.props.submit(values);
  }

  render() {
    const { myApplication: { loading } = {} } = this.props;

    return(
      <div className="Application">
        <Progressbar />
        <h1>Application</h1>
        <Message content={this.props.myApplication.error} ref={this.messageBoxRef} type="error" />
        {(() => (
          loading ?
          <LoadSpinner loading /> :
          (<Form
            submitForm={this.submitForm}
            loading={this.props.myApplication.submitLoading}
            formValues={this.props.myApplication.values}
            validationErrors={this.props.myApplication.validationErrors}
            jobPositions={this.props.resources.jobPositions}
            languages={this.props.resources.languages} />)
        ))()}
      </div>
    );
  }
};

function mapStateToProps({workers: state}) {
  const { checklist: {items}, resources, myApplication } = state;
  return {
    myApplication,
    checklistItems: items,
    resources: {
      jobPositions: resources.jobPositions,
      languages: resources.languages
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (formData) => dispatch(action.applicationSubmit(formData)),
    getJobPositionsList: () => dispatch(action.getJobPositionsList()),
    getLanguagesList: () => dispatch(action.getLanguagesList()),
    getMyApplication: () => dispatch(action.getMyApplication()),
    resetStates: () => dispatch(action.applicationPageLoad)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
