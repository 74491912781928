import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { workers as action } from '../../actions';
import LoadSpinner from '../LoadSpinner';
import Progressbar from '../Progressbar';
import Message from '../Message';
import Button from '../Button';
import { nextChecklistItem } from '../../utils';

class PersonalTaxCredits extends Component {
  constructor() {
    super(...arguments);
    this.fileUploadRef = React.createRef();
    this.handleClick = this.upload.bind(this);
  }

  componentDidMount() {
    this.props.getTaxCredit();
  }

  upload() {
    const file = this.fileUploadRef.current.files[0];
    this.props.upload(file);
  }

  render() {
    const errorMessage = _.get(this.props, 'error', null)
    const output = item => (
      <div className="Tax">
        <Progressbar />
        <h1>Personal Tax Credit Return</h1>
        <p>
          Download &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.canada.ca/content/dam/cra-arc/formspubs/pbg/td1on/td1on-fill-20e.pdf">2020 Ontario Personal Tax Credits Return</a>
          &nbsp; fill out and upload it here.
        </p>
        <Message content={errorMessage && errorMessage.error} type="error" />
        {item}
      </div>
    );

    return output(
      <div>
        {(() => {
          if(this.props.file_exists) {
            return (
              <React.Fragment>
                <div>You have uploaded Personal Tax Credit Return.</div>
                <div>
                  <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
                    Continue
                  </Link>
                </div>
              </React.Fragment>
            );
          }
          if(this.props.fetchLoading) {
            return (<LoadSpinner loading={this.props.fetchLoading} />);
          }
          return (
            <React.Fragment>
              <input type="file" ref={this.fileUploadRef} />
              <Button onClick={this.handleClick} className="btn btn-primary" loading={this.props.loading}>Upload</Button>
              <div>
                <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
                  Continue
                </Link>
              </div>
            </React.Fragment>
          );
        })()}
      </div>
    );
  }
}

function mapStateToProps({workers: {tax, checklist: {items}}}) {
  const {error, file_exists, loading, fetchLoading} = tax;
  return {
    error,
    file_exists,
    loading,
    fetchLoading,
    checklistItems: items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTaxCredit: () => dispatch(action.getTaxCredit()),
    upload: (formData) => dispatch(action.taxCreditUpload(formData))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalTaxCredits);
