import React, { Component } from 'react';
import { connect } from 'react-redux';
import DownloadDocumentButton from './DownloadDocumentButton';
import DeleteDocumentButton from './DeleteDocumentButton';

class Attachments extends Component {
  getList() {
    if(!this.props.attachments.length) return (<div>-</div>);
    const attachments = this.props.attachments.filter(a => a.type === 'other' && a.deleted !== true);
    const items = attachments.map((item, index) => {
      return (
        <li className="list-group-item" key={index}>
          <div style={{display: 'flex'}}>
            <span>{item.description}</span>
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              {(() => {
                if(!item.id || item.deleted) return null;
                return <DownloadDocumentButton documentId={item.id} />
              })()}
              {(() => {
                if(!item.id || item.deleted) return null;
                return <DeleteDocumentButton documentId={item.id} forever={true} onComplete={() => {
                  this.props.onChange();
                }} />
              })()}
            </div>
          </div>

        </li>
      );
    });
    return (
      <div className="list-group list-group-flush">{items}</div>
    );
  }

  render() {
    return (
      <div>
        <h5>Attachments</h5>
        {this.getList.call(this)}
      </div>
    );
  }
}

function mapStateToProps({admin: state}) {
  const {applications: {details}} = state;
  const {attachments} = details;
  return {
    attachments
  };
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
