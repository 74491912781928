import React from 'react';
import PropTypes from 'prop-types';
import LoadSpinner from './LoadSpinner';

export default function Button({className, disabled, style, onClick, loading, children}) {
  return (
    <button
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}>
      <LoadSpinner loading={loading} showLabel={false} />{children}
    </button>
  );
}

LoadSpinner.propTypes = {
  loading: PropTypes.bool
};
