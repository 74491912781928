import axios from 'axios';
import config from '../config';
import { getFileExtByMime } from '../utils';

const doc = {
  async getOne({type}) {
    const data = await axios.get( `${ config.API.URL }employee-documents/`, {params: {type}} );
    return data;
  },

  async saveTerms(formData) {
    try {
      const {data} = await axios.post( `${ config.API.URL }employee-documents/terms/`, formData);
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  },

  async saveResume(file) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const {data} = await axios.post( `${ config.API.URL }employee-documents/resume/`, formData);
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  },

  async saveTaxCredit(file) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const {data} = await axios.post( `${ config.API.URL }employee-documents/tax-credit/`, formData);
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  },

  /*
  async upload(filePath, {type}) {
    const formData = new FormData();
    formData.append('file', filePath);
    formData.append('type', type);
    const {data} = await axios.post( `${ config.API.URL }employee-documents/upload`, formData);
    return data;
  },
*/

  async download({type}) {
    const response = await axios.get( `${ config.API.URL }employee-documents/${type}/download/`, {
      responseType: 'blob',
      timeout: 30000
    });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${type}.${getFileExtByMime(response.type)}`);
    document.body.appendChild(link);
    link.click();
  },

  async saveManufacturingPolicy(formData) {
    try {
      const {data} = await axios.post( `${ config.API.URL }employee-documents/manufacturing-policy/`, formData);
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  }
}

export default doc;
