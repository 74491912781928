import axios from 'axios';
import _ from 'lodash';
import Joi from 'joi';
import config from '../config';

const validationRules = Joi.object().keys({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  middle_name: Joi.string().allow([null, '']).optional(),
  address: Joi.string().required(),
  city: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
  province: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
  postal_code: Joi.string().required(),
  country: Joi.alternatives().try(Joi.string(), Joi.number()).optional(),
  intersection: Joi.string().required(),
  tel_home: Joi.string().required(),
  tel_alternative: Joi.string().allow([null, '']).optional(),
  email: Joi.string().required(),
  dob: Joi.string().required(),
  sin: Joi.alternatives(Joi.string(), Joi.number()).required(),
  sin_exp_date: Joi.string().allow([null, '']).optional(),
  work_permit_bb: Joi.any().optional(),
  work_permit_exp_date: Joi.string().allow([null, '']).optional(),
  emergency_contact_name: Joi.string().required(),
  emergency_contact_phone: Joi.string().required(),
  legal_to_work: Joi.string().required(),
  referrer: Joi.string().required(),
  vehicle_access: Joi.string().required(),
  safety_shoes: Joi.string().required(),
  pounds_lift: Joi.number().required(),
  positions: Joi.string().required(),
  offence: Joi.string().required(),
  speak_eng: Joi.string().required(),
  write_eng: Joi.string().required(),
  work_fast: Joi.string().required(),
  can_lift: Joi.string().required(),
  avoid_product: Joi.any().optional(),
  allergies: Joi.any().optional(),
  worked_for_another: Joi.string().required(),

  hours_type: Joi.string().required(),
  employment_type: Joi.string().required(),
  shift_types: Joi.string().required(),
  min_salary: Joi.number().required(),
  date_of_availability: Joi.string().required(),
  educations: Joi.string().required(),
  certificates: Joi.string().allow([null, '']).optional(),
  languages: Joi.string().allow([null, '']).optional(),
  work_history_company_1: Joi.string().allow([null, '']).optional(),
  work_history_company_2: Joi.string().allow([null, '']).optional(),
  work_history_duties_1: Joi.string().allow([null, '']).optional(),
  work_history_duties_2: Joi.string().allow([null, '']).optional(),
  work_history_tel_1: Joi.alternatives(Joi.string(), Joi.number()).allow([null, '']).optional(),
  work_history_tel_2: Joi.alternatives(Joi.string(), Joi.number()).allow([null, '']).optional(),
  work_history_title_1: Joi.string().allow([null, '']).optional(),
  work_history_title_2: Joi.string().allow([null, '']).optional(),
  agencies: Joi.array().optional()
});

const application = {
  //Pass id as 'my-application' if loading for worker side. otherwise pass actual id
  async getApplication(id) {
    const {data} = await axios.get(`${ config.API.URL }applications/${id}/` );
    return data;
  },

  async save(id, formData) {
    try {
      const {data} = await axios.post( `${ config.API.URL }applications/${id}/`, formData );
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
    /*
    return axios.post( `${ config.API.URL }applications/my-application/`, formData )
    .then( res => res.data )
    .catch( err => {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    });
    */
  },

  async update(id, formData) {
    try {
      const {data} = await axios.put( `${ config.API.URL }applications/${id}/`, formData );
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
    /*
    return axios.put( `${ config.API.URL }applications/my-application/`, formData )
    .then( res => res.data )
    .catch( err => {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    });
    */
  },

  getSchema() {
    const getChildren = obj => {
      if(typeof obj === 'object' && ('children' in obj)) {
        let vals = {};
        Object.keys(obj.children).forEach((k) => {
          vals[k] = getChildren(obj.children[k]);
        });
        return vals;
      }
      if(typeof obj === 'object' && ('type' in obj)) {
        return obj.type;
      }
      return obj;
    };
    const blueprint = Joi.describe(validationRules);
    return getChildren(blueprint);
  },

  validate(formData) {
    const valid = Joi.validate(formData, validationRules, {abortEarly: false})
    if(valid.error) {
      const errorObject = {};
      valid.error.details.forEach(d => {
        _.set(errorObject, d.path.join('.'), {error: true, message: d.message});
      });
      return errorObject;
    }
    return {};
  }
}

export default application;
