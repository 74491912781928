import React, { Component } from 'react';
import config from '../../config';

export default class TermsText extends Component {
  render() {
    return (
      <div>
        <p>All OLA Staffing (OLA) assignment employees are placed on a 1 week probationary period for every
        new work assignment. If I (employee) quit or walk off the job within the probationary period my hourly
        pay rate will be automatically set to the minimum wage and my employment will be terminated without any notice.</p>

        <p><strong>Attendance:</strong> I must provide adequate notice within <strong>24 hours</strong> from the time I was supposed to start work.
        Failure to provide adequate notice may result in employment termination.</p>

        <p>Personal Illness or Emergency Leave: I understand that I am required to <strong><u>personally</u></strong> inform an <strong>OLA Staffing</strong> staff
        member at least 1 hour before the scheduled start of my shift if I cannot report to work. In case I am not able to
        reach an OLA Staffing staff by phone, I must inform my absence in writing by sending an email to info@olastaffing.com.</p>

        <p>At the end of each week it is my responsibility to fill out the OLA Timesheet, with the signature of authorized individual.
        (i.e. on-site supervisor, manager, owner)</p>

        <p>If I find my work assignment unsafe or are aware of any hazards in my workplace, I must report
        this immediately to my on-site supervisor and OLA Staffing.</p>

        <p>Our client is responsible for my on-site training and supervision.  If I feel I have not been trained appropriately, I must report
        this to my on-site supervisor and call OLA to explain.</p>

        <p>No Drugs or Alcohol will be tolerated before or while I am on the job, any violations of
        this policy will result in immediate dismissal.</p>

        <p>In case of any accident, injuries or near misses, I must call OLA as soon as possible and come in to the OLA Staffing
        office within 24 hours to fill out an incident report. In case of injury, transportation will be provided for me if necessary.</p>

        <p>I will not seek or accept employment with any company that I have been referred to for either a permanent or temporary positions by
        OLA without their expressed knowledge. This agreement is effective for a three month period from date of referral.</p>

        <p>I am responsible for my personal belongings.  OLA Staffing is not responsible for any lost or stolen articles at my assigned workplace.</p>

        <p>Any OLA belongings (i.e. safety shoes, safety glasses, gloves, etc) that were given to me for temporary usage must be returned to OLA. If the
        rented item is not returned or returned with damages I will be charged for the cost to replace the rented item.</p>

        <p>Transportation: Any bus tickets or travel expense monies provided to me will be charged on my pay cheque.
        If I am given a ride to my work location, I will be charged for the ride.</p>

        <p>I am responsible for my own working hours. I must make sure I sign in and sign out when placed at a company.
        Any missing worked hours must be reported to OLA Staffing immediately; <strong>Missing hours reported after 30 days will NOT BE PAID</strong></p>

        <p>I certify to the following: </p>

        <ol>
          <li>That I have read and understood OLA Staffing Inc.’s <a href={`${config.API.URL}common-files/Health and Safety Policy - Web.pdf`} target="_blank">Health and Safety Policy.</a></li>
        <li>That I have read and understood OLA Staffing Inc.’s <a href={`${config.API.URL}common-files/Violence and Harassment Policy - Web.pdf`} target="_blank">Violence and Harassment Policy.</a></li>
        <li>That I have read and understood the WHIMIS guidelines and wrote the OLA’s Health & Safety Test.
        I now have a good understanding of WHIMIS and basic Health & Safety.</li>
        <li>I have been provided with the “Your Employment Standards Rights: Temporary Help Agency Assignment
          Employees” Information Sheet as well as the <a href="https://files.ontario.ca/employment-standards-in-ontario.pdf" target="_blank">“Employment Standards Poster”</a>. I have read and understood
        my rights as a Temporary Help Agency Assignment Employee.</li>
        <li><strong>Employee Excess Hours Agreement:</strong> That I agree to work over 48 hours weekly, but not more than 60 hours
        in any one week if requested to do so by the client I am
        assigned to. I have also received, read and understand the most recent ‘Information for Employees About Hour of Work and Overtime Pay’ document.
        I also understand that I do have the option of refusing to work over 48 hours weekly. I also can cancel the ‘employee excess hours agreement’
        by giving OLA Staffing Inc. a two week notice.</li>
        <li>That I am aware that OLA will make deductions for CPP,EI and Income Tax.</li>
        <li>That I am also requesting to have my vacation pay paid out on each cheque. I will provide a written notice if I wish to accrue my vacation pay.</li>
        <li>That should I qualify for a public holiday pay, I only wish to get paid for the public holiday pay and are not opting for the substitute day off with public holiday pay.
        I will provide a written notice if I wish to receive a substitute day off with public holiday pay.</li>
        <li>That to the best of my knowledge and belief the statements made on this application are correct and if any is found contrary, it would result in my immediate dismissal.</li>
        <li>That I will read and agree to the terms and conditions of any assignment before acceptance, as OLA Staffing Inc. assumes no liability for the terms
        and condition of any job that I accept.</li>
        <li>That I have read and understood OLA’s Employee Terms and Conditions. Failure to comply could result in my dismissal.</li>
        <li><strong>I am legally entitled to work in Canada and understand that I may be required to show valid proof. (SIN, Photo ID)</strong></li>
        </ol>

      </div>
    );
  }

}
