import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { workers as action } from '../../actions';
import LoadSpinner from '../LoadSpinner';
import Signature from '../Signature';
import Progressbar from '../Progressbar';
import TermsText from './TermsText';
import Button from '../Button';
import doc from '../../services/doc';
import { nextChecklistItem } from '../../utils';

import './style.scss';

class DownloadButton extends Component {
  state = {
    loading: false
  };

  download() {
    this.setState({loading: true});
    doc.download({type: 'terms'})
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <React.Fragment>
      <Button
        loading={this.state.loading}
        onClick={this.download.bind(this)}
        className="btn btn-primary">Download</Button> signed document.
      </React.Fragment>
    );
  }

}

class Terms extends Component {
  constructor() {
    super(...arguments);
    this.signature = React.createRef();
    this.termsTextRef = React.createRef();
    this.handleClear = () => this.clear();
    this.handleSubmit = () => this.submit();
  }

  componentWillMount() {
    this.props.getTerms();
  }

  submit() {
    const fullName = (this.termsTextRef.current.value).trim();
    let signature = null;
    if(this.signature && this.signature.current && this.signature.current.pad) {
      signature = this.signature.current.pad;
    }
    if(!fullName.length || !signature.toData().length) {
      alert('Full name and/or Signature are missing');
      return;
    }
    this.props.submit({
      full_name: fullName,
      signature: signature.toDataURL('image/jpeg')
    });
  }

  clear() {
    if(this.signature && this.signature.current) {
      this.signature.current.pad.clear();
    }
  }

  render() {
    const output = (items) => (
      <div className="Terms">
        <Progressbar />
        <h1>Terms and Conditions</h1>
        {items}
      </div>
    );

    if(this.props.file_exists) {
      return output(
        <div>
          <div>You have signed Terms and Conditions. <DownloadButton /></div>
          <div>
            <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
              Continue
            </Link>
          </div>
        </div>
      );
    }

    if(this.props.fetchLoading) {
      return output(<LoadSpinner loading={this.props.fetchLoading} />);
    }

    return output(
      <div>
        <TermsText ref={this.termsTextRef} />
        <div>
          <label>Full Name<br />
            <input ref={this.termsTextRef} className="form-control" style={{width: '20em'}} />
          </label>
        </div>
        <div className="signaturepad">
          <div>Please sign your signature below</div>
          <Signature ref={this.signature} />
          <div className="actions">
            <button onClick={this.handleClear} type="button" className="btn btn-danger">Clear</button>
            <Button onClick={this.handleSubmit} type="button" className="btn btn-primary" loading={this.props.loading}>Submit</Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({workers: state}) {
  const { terms: { file_exists, loading, fetchLoading }, checklist: { items }} = state;
  return {
    loading,
    fetchLoading,
    file_exists,
    checklistItems: items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (formData) => dispatch(action.termsSubmit(formData)),
    getTerms: () => dispatch(action.getTerms()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
