import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import qs from 'qs';
import config from '../../config';
import './style.scss';
import { workers as action } from '../../actions';
import Button from '../Button';

class PasswordResetRequest extends Component {
  state = {
    error: null,
    recaptchaSuccess: (config.features.recaptcha) ? false : true
  };
  constructor() {
    super(...arguments);
    this.emailInputRef = React.createRef();
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler(e) {
    e.preventDefault();
    const email = this.emailInputRef.current.value.trim();

    this.props.passwordResetSubmit({email});
  }

  render() {
    const renderErrorMessage = () => {
      const { passwordReset } = this.props;
      const { error } = this.state;
      const _error = (error) ? error : (passwordReset && passwordReset.error);

      if(!_error) return null;
      return(
        <div className="alert-danger alert">{_error}</div>
      );
    }
    const renderInfoMessage = () => {
      let { info } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
      if(!info || !info.length) return null;
      return(
        <div className="alert alert-info">{info}</div>
      );
    }
    if(this.props.passwordReset.success) {
      return (
        <div className="PasswordReset">
          <h1>Reset Password</h1>
          A link to your email has been sent. Please click the link and reset the password.
          <Button className="btn btn-sm btn-primary" onClick={this.props.resetState}>Click here</Button> to provide another email.
        </div>
      );
    }
    return(
      <div className="PasswordReset">
        <h1>Reset Password</h1>
        {renderErrorMessage()}
        {renderInfoMessage()}
        <form action="" onSubmit={this.submitHandler}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" type="email" className="form-control" ref={this.emailInputRef} />
          </div>
          {(() => {
            if(!config.features.recaptcha) return null;
            return (
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  onChange={res => this.setState({recaptchaSuccess: !!res})} />
              </div>
            );
          })()}
          <div>
            <Button
              className="btn btn-primary btn-lg btn-submit"
              disabled={!this.state.recaptchaSuccess}
              loading={this.props.loading}
              onClick={this.submitHandler}>Submit</Button>
            <Link to="/login" className="btn btn-link" style={{marginTop: 18}}>Login</Link>
          </div>
        </form>
      </div>
    )
  }
}

class PasswordResetComplete extends Component {
  state = {
    error: null,
    recaptchaSuccess: (config.features.recaptcha) ? false : true
  };
  constructor() {
    super(...arguments);
    this.passwordInputRef = React.createRef();
    this.rePasswordInputRef = React.createRef();
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    const { token } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    this.props.resetState();
    this.props.passwordResetStep2Fetch(token);
  }

  componentWillReceiveProps({passwordReset, history}) {
    if(passwordReset.success) {
      const message = encodeURI('Password reset success. Please login with your new password.');
      history.push(`/login?info=${message}`);
    }
  }

  submitHandler(e) {
    e.preventDefault();
    const password = this.passwordInputRef.current.value.trim();
    const rePassword = this.rePasswordInputRef.current.value.trim();
    const { token } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

    if(password !== rePassword) {
      this.setState({error: 'Password did not match. Re-enter password.'});
      return;
    }
    this.props.passwordResetStep2Submit({token, password});
  }

  render() {
    const renderErrorMessage = () => {
      const { passwordReset } = this.props;
      const { error } = this.state;
      const _error = (error) ? error : (passwordReset && passwordReset.error);

      if(!_error) return null;
      return(
        <div className="alert-danger alert">{_error}</div>
      );
    }
    const renderInfoMessage = () => {
      let { info } = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
      if(!info || !info.length) return null;
      return(
        <div className="alert alert-info">{info}</div>
      );
    }
    if(!this.props.passwordReset.validToken) {
      return (
        <div className="PasswordReset">
          <h1>Reset Password Complete</h1>
          Invalid url. Please try again.
        </div>
      );
    }
    return(
      <div className="PasswordReset">
        <h1>Reset Password Complete</h1>
        {renderErrorMessage()}
        {renderInfoMessage()}
        <form action="" onSubmit={this.submitHandler}>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input id="password" type="password" className="form-control" ref={this.passwordInputRef} />
          </div>
          <div className="form-group">
            <label htmlFor="re_password">Retype Password</label>
            <input id="re_password" type="password" className="form-control" ref={this.rePasswordInputRef} />
          </div>
          {(() => {
            if(!config.features.recaptcha) return null;
            return (
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  onChange={res => this.setState({recaptchaSuccess: !!res})} />
              </div>
            );
          })()}
          <div>
            <Button
              className="btn btn-primary btn-lg btn-submit"
              disabled={!this.state.recaptchaSuccess}
              loading={this.props.loading}
              onClick={this.submitHandler}>Submit</Button>
            <Link to="/login" className="btn btn-link" style={{marginTop: 18}}>Login</Link>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps({passwordReset}) {
  return {passwordReset, loading: passwordReset.loading};
}

function mapDispatchToProps(dispatch) {
  return {
    passwordResetSubmit: formData => dispatch(action.passwordResetSubmit(formData)),
    passwordResetStep2Fetch: token => dispatch(action.passwordResetStep2Fetch(token)),
    passwordResetStep2Submit: formData => dispatch(action.passwordResetStep2Submit(formData)),
    resetState: () => dispatch(action.passwordResetResetState())
  };
}

export default {
  Request: connect(mapStateToProps, mapDispatchToProps)(PasswordResetRequest),
  Complete: connect(mapStateToProps, mapDispatchToProps)(PasswordResetComplete)
}
