import React from 'react';

export default function() {
  return (
    <div>
      <p>The purpose of this document is to outline the Good Manufacturing Practices (GMP) that must be followed
        while working with any client of OLA Staffing. As part of the OLA Staffing Food Safety Program, it is
        important that all employees of OLA Staffing are committed to good product handling and manufacturing
        practices while working with any of our clients.</p>

      <h3>HAIR:</h3>
      <ul>
        <li>All employees must wear a hairnet and beard net (if required) to prevent contamination from falling
          hairs. Beard nets are required for beards, goatees, soul patches or moustaches</li>
      </ul>

      <h3>UNIFORMS:</h3>
      <ul>
        <li>All are required to wear the prescribed uniform before entering the plant.</li>
        <li>Loose clothing must not be worn around machinery. Any loose clothing must be tucked in to uniform.</li>
        <li>Lab Coats must be removed before entering the restroom, lunchroom or leaving outside for break.</li>
        <li>Employees must wear clean lab coats only. Dirty lab coats must be stored in designated bin.</li>
        <li>CSA approved Safety Shoes must be worn in the production or warehouse area at all times.</li>
        <li>Gloves must be worn according to the client’s glove policy.</li>
      </ul>
      <h3>PERSONAL HYGIENE:</h3>
      <ul>
        <li>Employees entering the plant must maintain a high standard of personal hygiene. Employees must wash,
          dry with paper towel and sanitize their hands frequently before and after breaks, at bathroom breaks and
          before entering the production areas.</li>
        <li>Any overpowering aftershave/perfume will not be permitted in the production plant.</li>
        <li>Any nail polish, false nails or false eyelashes must be removed before entering the plant.</li>
        <li>Fingernails should be kept short and clean while working in the production plant.</li>
        <li>Tissues, kleenex or reusable cloth handkerchiefs are not allowed in the production plant.</li>
      </ul>

      <h3>JEWELLERY:</h3>
      <ul>
        <li>Any Employee working in a production area must remove all jewellery, including watches, earrings,
          rings, chains, bracelets and studs in exposed parts of the body, such as ears, nose, tongue and eyebrows
          etc. Medical Alert Bracelets are allowed if covered and secured.</li>
      </ul>
      <h3>CLOTHING:</h3>
      <ul>
        <li>Sandals or high heel shoes are not permitted within the production area.</li>
        <li>Glasses cannot be worn without a protective band.</li>
      </ul>
      <h3>PERSONAL EFFECTS:</h3>
        <ul>
          <li>Personal effects shall be stored or placed at the security office or at reception. Examples would include
            sweaters, jackets, shoes, purses, smoking materials, etc.</li>
          <li>Electronic devices of any kind are strictly prohibited in the production or warehouse.</li>
        </ul>
        <h3>NO FOODS &amp; EATING ALLOWED:</h3>
        <ul>
          <li>Consumption and storage of food, beverages and chewing gum are prohibited inside the plant and
            restricted to designated areas only.</li>
          <li>Smoking is prohibited anywhere in the plant or warehouse facility. Please use designated areas only.</li>
        </ul>
        <h3>ILLNESS/INJURIES:</h3>
        <ul>
          <li>All Employees are required to report to their supervisor any illness or symptoms involving vomiting,
          diarrhea, or other stomach disorder, prior to and during employment. Decisions to be allowed into the
          production area will be based on individual circumstances.</li>
          <li>Any Employee who is suffering from skin infections, boils, sores, infected wounds, heavy cold, ear or
          eye discharge or any other infectious or communicable disease shall not be permitted inside the plant
          until they have obtained medical clearance.</li>
          <li>All cuts and grazes on exposed skin shall be covered only by a blue metal detectable bandage.</li>
          <li>Any Employee bringing personal medicines onto site shall notify their supervisor/reception. Medicines
          should be kept secure in lockers. If an Employee needs to carry medicines into the plant, the supervisor
          shall give permission and the Employee shall be responsible for ensuring security of these items.</li>
        </ul>
        <h3>SAFETY/SECURITY:</h3>
        <ul>
          <li>Follow Allergen Preventive procedures to avoid cross contamination.</li>
          <li>Incidents such as suspicious activity (including suspicious packages), threats, fire, gas leak etc. must be
            reported to your supervisor immediately.</li>
          <li>Employees must be at his/her assigned area at all times. Wandering in other areas is strictly prohibited.</li>
        </ul>
    </div>
  );
}
