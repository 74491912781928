import checklist from '../services/checklist';
import notifications from '../services/admin/notifications';
import applications from '../services/admin/applications';
import users from '../services/admin/users';

const getApplications = (page, params) => dispatch => {
  dispatch({
    type: 'APPLICATIONS_WILL_FETCH'
  });

  applications.paginate(page, params).then(payload => dispatch({
    type: 'APPLICATIONS_DID_FETCH',
    payload
  }));
};

const getApplicationDetails = userId => dispatch => {
  dispatch({
    type: 'APPLICATION_DETAILS_WILL_FETCH'
  });
  const promises = [checklist.fetch(userId), users.fetchApplication(userId)];

  Promise.all(promises).then(([checklist, activeApplication]) => {
    dispatch({
      type: 'APPLICATION_DETAILS_DID_FETCH',
      payload: {
        checklist,
        activeApplication
      }
    });
  })
  .catch(console.log);
};

const getAttachments = userId => dispatch => {
  dispatch({
    type: 'ATTACHMENTS_WILL_FETCH'
  });
  users.fetchAttachments(userId)
    .then(attachments => {
      dispatch({
        type: 'ATTACHMENTS_DID_FETCH',
        payload: attachments
      });
    })
    .catch(console.log);
};


const getNotifications = (page, params) => dispatch => {
  dispatch({
    type: 'NOTIFICATIONS_WILL_FETCH'
  });

  notifications.paginate(page, params)
    .then(payload => {
      dispatch({
        type: 'NOTIFICATIONS_DID_FETCH',
        payload
      });
    })
    .catch(console.log);
};

const markReadStatus = (id, status) => dispatch => {
  dispatch({
    type: 'NOTIFICATIONS_MARK_STATUS_WILL_SUBMIT',
    payload: {id}
  });

  notifications.markReadStatus(id, status)
    .then(payload => {
      dispatch({
        type: 'NOTIFICATIONS_MARK_STATUS_DID_SUBMIT',
        payload
      });
    });
};

export default {
  getApplications,
  getNotifications,
  markReadStatus,
  getApplicationDetails,
  getAttachments
};
