import axios from 'axios';
import config from '../../config';
import { getFileExtByMime } from '../../utils';

const doc = {
  async download(id) {
    const response = await axios.get( `${ config.API.URL }admin/employee-documents/download/${id}`, {
      responseType: 'blob',
      timeout: 30000
    });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}.${getFileExtByMime(response.type)}`);
    document.body.appendChild(link);
    link.click();
  },

  async update(id, newValues = {}) {
    const {data} = await axios.put( `${ config.API.URL }admin/employee-documents/${id}`, {
      ...newValues
    });
    return data;
  },

  async remove(id, forever = false) {
    const {data} = await axios.delete( `${ config.API.URL }admin/employee-documents/${id}`, {
      params: {
        forever
      }
    });
    return data;
  },

  async upload(userId, file, type, {description}) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      formData.append('description', description);
      formData.append('user_id', userId);
      const {data} = await axios.post( `${ config.API.URL }admin/employee-documents/`, formData);
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  }
}

export default doc;
