import _ from 'lodash';
import { combineReducers } from 'redux';

const applicationInitialState = {
  applications: [],
  total: 0,
  loading: false,
  details: {
    activeApplication: {},
    checklist: [],
    attachments: [],
    loading: false
  }
};

function applicationsReducers(defaultState = applicationInitialState, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'APPLICATIONS_WILL_FETCH':
      state.loading = true;
      break;

    case 'APPLICATIONS_DID_FETCH':
      state.applications = payload.data;
      state.loading = false;
      state.total = payload.total;
      state.limit = payload.limit;
      break;

    case 'APPLICATION_DETAILS_WILL_FETCH':
      state.details.loading = true;
      break;

    case 'APPLICATION_DETAILS_DID_FETCH':
      state.details.activeApplication = payload.activeApplication;
      state.details.checklist = payload.checklist;
      state.details.loading = false;
      break;

    case 'ATTACHMENTS_DID_FETCH':
      state.details.attachments = payload;
      break;

    default:
      // Nothing
  }
  return state;
}

const notificationsInitialState = {
  notifications: [],
  loading: false,
  total: 0
};

function notificationsReducers(defaultState = notificationsInitialState, { type, payload }) {
  let state = _.cloneDeep(defaultState);
  switch(type) {
    case 'NOTIFICATIONS_WILL_FETCH':
      state.loading = true;
      break;

    case 'NOTIFICATIONS_DID_FETCH':
      state.notifications = payload.data;
      state.total = payload.total;
      state.limit = payload.limit;
      state.loading = false;
      break;

    case 'NOTIFICATIONS_MARK_STATUS_WILL_SUBMIT':
      {
        const notifications = state.notifications;
        const notification = _.find(notifications, ({id}) => payload.id === id);
        notification.loading = true;
        state.notifications = notifications;
      }
      break;

    case 'NOTIFICATIONS_MARK_STATUS_DID_SUBMIT':
      {
        const notifications = state.notifications;
        const index = _.findIndex(notifications, ({id}) => payload.id === id);
        notifications.splice(index, 1)
        state.notifications = notifications;
        state.total -= 1;
      }
      break;

    default:
      // Nothing
  }
  return state;
}

export default combineReducers({
  applications: applicationsReducers,
  notifications: notificationsReducers
});
