import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const CheckboxGroup = ({name = null, items = [], selected = [], onChange = () => {}, className}) => {
  if(!items.length || !name) return null;

  if(!selected) {
    selected = [];
  }
  if(typeof selected === 'string') {
    selected = selected.split(',');
  }
  const values = selected;
  const onChangeHandler = ({target}) => {
    const {checked, value} = target;
    const itemIndex = values.indexOf(value);

    if (checked && itemIndex=== -1) {
      values.push(value);
    } else if (!checked && itemIndex > -1) {
      values.splice(itemIndex, 1);
    }
    onChange(values.join(','));
  };

  const checkboxes = items.map((item, index) => {
    const inputId = uniqueId('chgroup');
    return (
      <div key={index}>
        <input
          type="checkbox"
          id={`${inputId}`}
          checked={(values || []).indexOf(String(item.value)) > -1}
          onChange={onChangeHandler}
          value={item.value} />
        <label htmlFor={`${inputId}`} style={{display: 'inline'}}>{item.label}</label>
      </div>
    );
  });
  return (
    <div className={`CheckboxGroup ${className}`}>
      {checkboxes}
    </div>
  );
};

export default CheckboxGroup;

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]), //Comma seperated string
  onChange: PropTypes.func.isRequired
};
