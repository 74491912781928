import React from 'react';

export default ({name = null, items = [], selected = null, onChange = () => {}, className}) => {
  if(!items.length || !name) return null;

  const onChangeHandler = ({target}) => {
    const {checked, value} = target;
    if(checked) onChange(value);
  }

  const radios = items.map((item, index) => (
    <span key={`${name}${index}`}>
      <input
        type="radio"
        className={item.className}
        id={`${name}${index}`}
        checked={item.value === selected}
        onChange={onChangeHandler}
        value={item.value}
      />
      <label htmlFor={`${name}${index}`} className={item.className}>{item.label}</label>
    </span>
  ));

  return (
    <div className={`RadioGroup ${className}`}>
      {radios}
    </div>
  );
};
