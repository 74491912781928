import React, { Component } from 'react';
import { connect } from 'react-redux';

class Progressbar extends Component {
  getProgress() {
    let totalItems = 0;
    let completed = 0;
    this.props.checklistItems.forEach(item => {
      if(!item.required) return;
      totalItems += 1;
      if(item.status && !item.deleted) {
        completed += 1;
      }
    });
    return Math.ceil((completed/totalItems) * 100);
  }
  render() {
    const progress = this.getProgress() || 1;
    const style = {
      width: `${progress}%`
    };
    return (
      <React.Fragment>
      <div style={{marginTop: '10px'}}>
        {progress}% completed
        <div className="progress" style={{height: '1px'}}>
          <div className="progress-bar" role="progressbar" style={style} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps({workers: {checklist: {items}}}) {
  return {
    checklistItems: items
  };
}

export default connect(mapStateToProps)(Progressbar);
