import React, { Component } from 'react';
import { connect } from 'react-redux';
import Details from '../applications/Details';
import doc from '../../../services/admin/doc';
import { admin as action } from '../../../actions';
import Tabbar from '../../Tabbar';
import LoadSpinner from '../../LoadSpinner';
import Pagination from '../../Pagination';
import Button from '../../Button';
import Time from '../../Time';
import Modal from '../../Modal';
import './index.scss';

class DownloadDocumentButton extends Component {
  state = {
    loading: false
  };

  downloadDocument() {
    let documentType = null;
    const {type, info} = this.props;
    if(type === 'TERMS_SIGNED') documentType = 'terms';
    if(type === 'TAX_CREDIT_UPLOADED') documentType = 'tax_credit';
    if(type === 'GMP_SIGNED') documentType = 'gmp';
    if(type === 'RESUME_UPLOADED') documentType = 'resume';

    if(!info[documentType] || !info[documentType].id) return;
    this.setState({loading: true});
    doc.download(info[documentType].id)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <Button
        className="btn btn-sm btn-info"
        loading={this.state.loading}
        onClick={() => this.downloadDocument.call(this)}>Download Document</Button>
    );
  }
}

class NotificationItem extends Component {
  get title() {
    const {type} = this.props;
    if(type === 'APPLICATION_REGISTRATION') return 'New Application';
    if(type === 'APPLICATION_UPDATE') return 'Application Updated';
    if(type === 'USER_REGISTRATION') return 'New User Registration';
    if(type === 'TAX_CREDIT_UPLOADED') return 'New Tax Credit Upload';
    if(type === 'TERMS_SIGNED') return 'New Terms Signed';
    if(type === 'GMP_SIGNED') return 'New GMP Signed';
    if(type === 'QUIZ_COMPLETED') return 'New Quiz submitted';
    if(type === 'RESUME_UPLOADED') return 'Resume Uploaded';
    return 'Other';
  }

  get info() {
    const {type, info} = this.props;
    if(type === 'USER_REGISTRATION') {
      const {email} = info || {};
      return (<div>Email: {email}</div>);
    }

    if(type === 'QUIZ_COMPLETED') {
      const {name, quiz} = info || {};
      if(quiz && quiz.pass === false) {
        return (
          <React.Fragment>
            <div>Applicant: {name}</div>
            <div>Score: Fail</div>
          </React.Fragment>
        );
      }
      if(!quiz || !quiz[1]) return null;
      let quizType = quiz[1] && quiz[1].type;
      let quizName = '';
      if(quizType === 'health-safety') {
        quizName = 'Health and Safety at Work';
      } else if(quizType === 'whmis') {
        quizName = 'WHMIS 2015 Testing'
      }
      return (
        <React.Fragment>
          <div>Applicant: {name}</div>
          <div>Quiz: {quizName}</div>
          <div>Score: {quiz[1].score}</div>
        </React.Fragment>
      );
    }
    if([
      'APPLICATION_REGISTRATION', 'APPLICATION_UPDATE',
      'TAX_CREDIT_UPLOADED', 'TERMS_SIGNED',
      'GMP_SIGNED', 'RESUME_UPLOADED'
    ].indexOf(type) > -1) {
      const {name} = info || {};
      return (<div>Applicant: {name}</div>);
    }

    return null;
  }

  getActionButtons() {
    const {type, read, onAction, userId, info, loading} = this.props;
    const buttons = [
      <Button key="read" className="btn btn-dark btn-sm" onClick={() => onAction('markRead', !read)} loading={loading}>
        Mark as {`${read ? 'Unread' : 'Read'}`}
      </Button>
    ];
    if(['TAX_CREDIT_UPLOADED', 'RESUME_UPLOADED', 'TERMS_SIGNED', 'GMP_SIGNED'].indexOf(type) > -1) {
      const {application_id} = info;
      buttons.unshift(
        <DownloadDocumentButton key="download-document" userId={userId} type={type} info={info} />,
        <a
          key="view-application"
          className="btn btn-sm btn-info"
          rel="noopener noreferrer"
          href={`http://olastaffing.com/admin/index.php?option=forms&form_id=32&JobApplicationId=${application_id}`} target="_blank">
         View Application</a>
      );
    }
    if(type === 'APPLICATION_REGISTRATION' || type === 'APPLICATION_UPDATE') {
      const {application_id} = info || {};
      buttons.unshift(
        <a
          key="view-application"
          className="btn btn-sm btn-info"
          rel="noopener noreferrer"
          href={`http://olastaffing.com/admin/index.php?option=forms&form_id=32&JobApplicationId=${application_id}`} target="_blank">
         View Application</a>
      );
    }

    if(type !== 'USER_REGISTRATION') {
      buttons.unshift(
        <button
          key="details"
          className="btn btn-sm btn-info"
          onClick={() => this.props.onAction('details', {userId, applicationId: info.application_id})}>
        View Details</button>
      );
    }

    return (
      <React.Fragment>
        <div className="btn-group">{buttons}</div>
      </React.Fragment>
    );
  }

  render() {
    const {createdAt, enableActions} = this.props;

    return (
      <div className="Notification">
        <div className="info">
          <div>
            <div className="title">{this.title}</div>
            <div>{this.info}</div>
            <Time className="time" timestamp={createdAt} format="relative" />
          </div>
          <div className="actions">
            {enableActions && this.getActionButtons()}
          </div>
        </div>
      </div>
    );
  }
}

class Notifications extends Component {
  state = {
    read: false,
    currentPage: 1,
    activeTab: 'unread',
    showDetails: false,
    activeApplicationId: null,
    activeUserId: null
  };

  componentDidMount() {
    this.loadNotifications(this.state.currentPage);
  }

  loadNotifications(page) {
    this.props.getNotifications(page, {read: this.state.read});
  }

  render() {
    const rows = this.props.notifications.map(({id, type, read, info, user_id, created_at, loading}) => {
      return (
        <li key={id} className="list-group-item">
          <NotificationItem
            type={type}
            info={info}
            userId={user_id}
            id={id}
            read={read}
            loading={loading}
            enableActions={this.state.read !== null}
            onAction={(actionType, actionData) => {
              if(actionType === 'markRead') {
                this.props.markReadStatus(id, actionData);
              }
              if(actionType === 'details') {
                this.props.getApplicationDetails(actionData.userId);
                this.setState({
                  showDetails: true,
                  activeApplicationId: actionData.applicationId,
                  activeUserId: actionData.userId
                });
              }
            }}
            createdAt={created_at} />
        </li>
      );
    });
    return (
      <div className="Notifications">
        <h3>Notifications <LoadSpinner loading={this.props.loading} showLabel={false} /></h3>
        <Tabbar
          tabs={[{label: 'Unread', value: 'unread'}, {label: 'Read', value: 'read'}, {label: 'All', value: 'all'}]}
          activeTab={this.state.activeTab}
          onTabChange={activeTab => {
            let read = (activeTab === 'read') ? true : (activeTab === 'unread') ? false : null;
            this.setState({activeTab, read, currentPage: 1}, () => this.loadNotifications.call(this, 1));
          }}
        />
        {(() => {
          if(!rows.length) return (<div style={{fontStyle: 'italic', margin: '2em 0'}}>All covered.</div>);
          return (<ul className="list-group list-group-flush">{rows}</ul>);
        })()}

        <Pagination
          total={this.props.total}
          currentPage={this.state.currentPage}
          limit={this.props.limit}
          onPageClick={page => {
            this.setState({currentPage: page});
            this.loadNotifications(page);
          }} />
        <Modal
          title="Applicant Details"
          size="large"
          show={this.state.showDetails}
          onClose={() => this.setState({showDetails: false})}><Details /></Modal>
      </div>
    );
  }
}

function mapStateToProps({admin: state}) {
  const {
    notifications: {notifications, total, limit, loading},
    applications: {details: {checklist}}
  } = state;
  return {
    notifications,
    total,
    limit,
    loading,
    checklist
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotifications: (page, filters) => dispatch(action.getNotifications(page, filters)),
    markReadStatus: (id, status) => dispatch(action.markReadStatus(id, status)),
    getApplicationDetails: id => dispatch(action.getApplicationDetails(id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
