import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import 'react-dates/initialize';
import App from './App';
import * as serviceWorker from './serviceWorker';

import rootReducer from './reducers';

import './css/fontello.scss';
import 'react-dates/lib/css/_datepicker.css';

const middleware = [thunk];
const composeArgs = [applyMiddleware(...middleware)];
if(window.__REDUX_DEVTOOLS_EXTENSION__) {
  composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
const store = createStore(
  rootReducer,
  compose(...composeArgs),
);

ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
