export const SHIFT_TYPES = [{label: 'Morning', value: 'Morning'}, {label: 'Evening', value: 'Evening'},
  {label: 'Midnight', value: 'Midnight'}, {label: 'Continental', value: 'Continental'},
  {label: 'Rotational', value: 'Rotational'}, {label: 'Weekend', value: 'Weekend'}];

export const EDUCATION_TYPES = [
  {label: 'No Education', value: 'No Education'},
  {label: 'Elementary', value: 'Elementary'},
  {label: 'Some High School', value: 'Some High School'},
  {label: 'Completed High School', value: 'Completed High School'},
  {label: 'Some College', value: 'Some College'},
  {label: 'Completed College', value: 'Completed College'},
  {label: 'Some University', value: 'Some University'},
  {label: 'Completed University', value: 'Completed University'},
  {label: 'Post Graduate', value: 'Post Graduate'},
];

export const HOURS_TYPE = [
  {label: 'Full Time', value: 'Full time'},
  {label: 'Part Time', value: 'Part time'}
];

export const AVOID_PRODUCT = [
  {label: 'Alcohol', value: '2'},
  {label: 'Dust', value: '3'},
  {label: 'Meat', value: '4'},
  {label: 'Pet', value: '1'}
];

export const EMPLOYMENT_TYPE = [
  {label: 'Permanent', value:'Permanent'},
  {label: 'Temporary', value:'Temporary'},
  {label: 'Contract', value:'Contract'},
  {label: 'On call', value:'On call'}
];

export const YES_NO = [
  {label: 'Yes', value: 'Y'},
  {label: 'No', value: 'N'}
];

export const SPEAK_ENG = [
  {label: 'Below Average (Not Good)', value: 'B'},
  {label: 'Average (Little Bit)', value: 'A'},
  {label: 'Exceptional (Very Good)', value: 'E'}
];
