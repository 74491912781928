import axios from 'axios';
import _ from 'lodash';
import Joi from 'joi';
import config from '../config';

const whmisValidationRules = Joi.object().keys({
  q1: Joi.string().required(),
  q2: Joi.string().required(),
  q3: Joi.string().required(),
  q4: Joi.string().required(),
  q5: Joi.string().required(),
  q6: Joi.string().required(),
  q7: Joi.string().required(),
  q8: Joi.string().required(),
  q9: Joi.string().required(),
  q10: Joi.string().required(),
  q11: Joi.string().required(),
  q12: Joi.string().required(),
  q13: Joi.string().required(),
  q14: Joi.string().required()
});

const healthSafetyValidationRules = Joi.object().keys({
  q1: Joi.string().required(),
  q2: Joi.string().required(),
  q3: Joi.string().required(),
  q4: Joi.string().required(),
  q5: Joi.string().required(),
  q6: Joi.string().required(),
  q7: Joi.string().required(),
  q8: Joi.string().required(),
  q9: Joi.string().required(),
  q10: Joi.string().required(),
  q11: Joi.string().required(),
  q12: Joi.string().required(),
  q13: Joi.string().required(),
  q14: Joi.string().required(),
  q15: Joi.string().required(),
  q16: Joi.string().required(),
  q17: Joi.string().required(),
  q18: Joi.string().required(),
  q19: Joi.string().required()
});

const quiz = {
  async getWhmis() {
    try {
      const {data} = await axios.get( `${ config.API.URL }employee-quiz/`,{params: {
        type: 'whmis'
      }});
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  },

  validateWhmis(formData) {
    const valid = Joi.validate(formData, whmisValidationRules, {abortEarly: false})
    if(valid.error) {
      const errorObject = {};
      valid.error.details.forEach(d => {
        _.set(errorObject, d.path.join('.'), {error: true, message: d.message});
      });
      return errorObject;
    }
    return {};
  },

  async saveWhmis(formData) {
    let response = null;
    try {
      const {data} = await axios.post( `${ config.API.URL }employee-quiz/`, {
        type: 'whmis',
        answers: formData
      });
      response = data;
    } catch(err) {
      const res = err.response;
      let  error = err;
      if(res && res.data) {
        error = res.data;
      }
      throw new Error(error);
    }
    return response;
  },

  async getHealthSafety() {
    try {
      const {data} = await axios.get( `${ config.API.URL }employee-quiz/`,{params: {
        type: 'health-safety'
      }});
      return data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if(res && res.data) {
        error = res.data;
      }
      return Promise.reject(error);
    }
  },

  validateHealthSafety(formData) {
    const valid = Joi.validate(formData, healthSafetyValidationRules, {abortEarly: false})
    if(valid.error) {
      const errorObject = {};
      valid.error.details.forEach(d => {
        _.set(errorObject, d.path.join('.'), {error: true, message: d.message});
      });
      return errorObject;
    }
    return {};
  },

  async saveHealthSafety(formData) {
    let response = null;
    try {
      const {data} = await axios.post( `${ config.API.URL }employee-quiz/`, {
        type: 'health-safety',
        answers: formData
      });
      response = data;
    } catch(err) {
      const res = err.response;
      let  error = err;
      if(res && res.data) {
        error = res.data;
      }
      throw new Error(error);
    }
    return response;
  }
}

export default quiz;
