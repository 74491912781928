export const focusElement = (elem) => {
  if(!elem) return;
  const originalTabIndex = elem.tabIndex;
  elem.tabIndex = 0;
  elem.focus();
  setTimeout(() => {
    elem.tabIndex = originalTabIndex;
  });
};

export const getFileExtByMime = (mime) => {
	if(mime === 'application/pdf') return 'pdf';
	if(mime === 'application/msword') return 'doc';
	if(mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'docx';
	if(mime === 'image/png') return 'png';
	if(mime === 'image/jpeg') return 'jpg';
}

export const nextChecklistItem = (checklistItems) => {
  let nextItem = null;
  const map = {
    'application': '/application',
    'health-safety': '/health-and-safety',
    'whmis': '/whmis',
    'terms': '/terms-and-conditions',
    'manufacturing-policy': '/good-manufacturing-policy',
    'resume': '/resume',
    'tax-credit': '/personal-tax-credits',
  };
  for(let i = 0; i < checklistItems.length; i += 1) {
    const item = checklistItems[i];
    if(item.required === false) continue;
    if(item.status === false || item.deleted === true) {
      nextItem = item.key;
      break;
    }
  }
  return (nextItem in map) ? map[nextItem] : null;
};
