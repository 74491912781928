import axios from 'axios';
import config from '../../config';

export default {
  async fetchApplication(userId) {
    let response = null;
    try {
      const {data} = await axios.get(`${ config.API.URL }admin/users/${userId}/application`);
      response = data;
    } catch(err) {
      throw err;
    }
    return response;
  },

  async fetchAttachments(userId) {
    let response = null;
    try {
      const {data} = await axios.get(`${ config.API.URL }admin/users/${userId}/attachments`);
      response = data;
    } catch(err) {
      throw err;
    }
    return response;
  }
}
