import React, { Component } from 'react';
import doc from '../../../services/admin/doc';
import Button from '../../Button';

export default class DeleteDocumentButton extends Component {
  state = {
    loading: false
  };

  removeDocument() {
    const answer = window.confirm('Do you want to remove this document?');
    if(!answer) return false;
    const {documentId, forever, onComplete} = this.props;
    this.setState({loading: true});
    doc.remove(documentId, forever)
      .then(() => {
        this.setState({loading: false});
        if(onComplete) onComplete();

      })
      .catch(() => {
        this.setState({loading: false});
        if(onComplete) onComplete();
      });
  }

  render() {
    if(!this.props.documentId) return null;
    return (
      <Button
        className="btn btn-sm btn-danger"
        loading={this.state.loading}
        onClick={() => this.removeDocument.call(this)}>Delete</Button>
    );
  }
}
