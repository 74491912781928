import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { admin as action } from '../../../actions';

import Checklist from './Checklist';
import './Details.scss';

const getGender = symbol => (symbol.toLowerCase() === 'm') ? 'Male' : (symbol.toLowerCase() === 'f') ? 'Female' : symbol;

class DetailsComponent extends Component {
  render() {
    const {activeApplication, checklist} = this.props;
    if(!activeApplication) return null;
    const {
      first_name,
      last_name,
      address,
      city,
      postal_code,
      tel_alternative,
      tel_home,
      intersection,
      gender,
      min_salary,
      dob,
      rating,
      status
    } = activeApplication;

    return (
      <div className="Details">
        <h5>Details</h5>
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <td><span>Name</span>{first_name} {last_name}</td>
              <td><span>Gender/Age</span>{gender ? getGender(gender) : 'Unknown'} {moment().diff(dob, 'years')}</td>
            </tr>
            <tr>
              <td><span>Address</span>{address}, {city}, {postal_code}</td>
              <td><span>Intersection</span>{intersection}</td>
            </tr>
            <tr>
              <td><span>Tel</span>{tel_alternative} {tel_home}</td>
              <td><span>Min Salary</span>${Number(min_salary).toFixed(2)}</td>
            </tr>
            <tr>
              <td><span>Worker status</span>{status}</td>
              <td><span>Rating</span>{rating}</td>
            </tr>
          </tbody>
        </table>
        <h5>Checklist</h5>
        <Checklist
          applicationId={activeApplication.id}
          userId={activeApplication.user_id}
          items={checklist}
          onDataChange={() => {this.props.getDetails(activeApplication.user_id)}}
        />
      </div>
    );
  }
}

function mapStateToProps({admin: state}) {
  const {applications: {details}} = state;
  const {checklist, activeApplication} = details;
  return {
    activeApplication,
    checklist
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetails: id => dispatch(action.getApplicationDetails(id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
