import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { workers as action } from '../../actions';
import LoadSpinner from '../LoadSpinner';
import Progressbar from '../Progressbar';
import Signature from '../Signature';
import ManufacturingPolicyText from './ManufacturingPolicyText';
import Button from '../Button';
import doc from '../../services/doc';
import { nextChecklistItem } from '../../utils';

class DownloadButton extends Component {
  state = {
    loading: false
  };

  download() {
    this.setState({loading: true});
    doc.download({type: 'manufacturing-policy'})
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <React.Fragment>
      <Button
        loading={this.state.loading}
        onClick={this.download.bind(this)}
        className="btn btn-primary">Download</Button> signed document.
      </React.Fragment>
    );
  }
}
class ManufacturingPolicy extends Component {
  constructor() {
    super(...arguments);
    this.signature = React.createRef();
    this.policyTextRef = React.createRef();
    this.handleClear = () => this.clear();
    this.handleSubmit = () => this.submit();
  }

  componentWillMount() {
    this.props.getManufacturingPolicy();
  }

  submit() {
    const fullName = (this.policyTextRef.current.value).trim();
    let signature = null;
    if(this.signature && this.signature.current && this.signature.current.pad) {
      signature = this.signature.current.pad;
    }
    if(!fullName.length || !signature.toData().length) {
      alert('Full name and/or Signature are missing');
      return;
    }
    this.props.submit({
      full_name: fullName,
      signature: signature.toDataURL('image/jpeg')
    });
  }

  clear() {
    if(this.signature && this.signature.current) {
      this.signature.current.pad.clear();
    }
  }

  render() {
    const output = (items) => (
      <div className="ManufacturingPolicy">
        <Progressbar />
        <h1>Good Manufacturing Practices Policy</h1>
        {items}
      </div>
    );

    if(this.props.file_exists) {
      return output(
        <div>
          <div>You have signed "Good Manufacturing Practices Policy". <DownloadButton /></div>
          <div>
            <Link to={nextChecklistItem(this.props.checklistItems) || '/checklist'} className="btn btn-primary btn-submit btn-lg">
              Continue
            </Link>
          </div>
        </div>
      );
    }

    if(this.props.fetchLoading) {
      return output(<LoadSpinner loading={this.props.fetchLoading} />);
    }

    return output(
      <div>
        <ManufacturingPolicyText />
        <p>
          <label>Full Name<br />
            <input ref={this.policyTextRef} className="form-control" style={{width: '20em'}} />
          </label>
        </p>
        <div className="signaturepad">
          <div>Please sign your signature below</div>
          <Signature ref={this.signature} />
          <div className="actions">
            <button onClick={this.handleClear} type="button" className="btn btn-danger">Clear</button>
            <Button onClick={this.handleSubmit} type="button" className="btn btn-primary" loading={this.props.loading}>Submit</Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({workers: state}) {
  const { manufacturingPolicy: { file_exists, download, loading, fetchLoading }, checklist: { items }} = state;
  return {
    loading,
    fetchLoading,
    file_exists,
    download,
    checklistItems: items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (formData) => dispatch(action.manufacturingPolicySubmit(formData)),
    getManufacturingPolicy: () => dispatch(action.getManufacturingPolicy()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturingPolicy);
