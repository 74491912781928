import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { groupBy, uniqueId } from 'lodash';
import Cleave from 'cleave.js/react';
import { workers as action } from '../../actions';

import Button from '../Button';
import CheckboxGroup from '../input/CheckboxGroup';
import RadioGroup from "../input/RadioGroup";
import SelectInput from '../input/Select';
import {
  SHIFT_TYPES,
  EDUCATION_TYPES,
  HOURS_TYPE,
  AVOID_PRODUCT,
  EMPLOYMENT_TYPE,
  YES_NO,
  SPEAK_ENG
} from './terms';

import "react-datepicker/dist/react-datepicker.css";

const validate = (field, validationErrors, classNames = '') => {
  const {error, message} = validationErrors[field] || {};
  if(error) return {className: 'is-invalid ' + classNames, message};
  return {className: classNames};
}

const renderJobPositionsList = (positions = [], selected = [], validationErrors, onChange = () => {}) => {
  if(!positions.length) return null;
  const group = groupBy(positions, 'PositionCatId');
  let output = [];
  for(let i = 1; i <= 5; i += 1) {
    let positions = group[i].map(pos => ({
      value: pos.PositionId,
      label: pos.PositionDesc
    }));
    const internalOnChange = values => onChange('positions', values);
    output.push(
      <div className="col-md-2" key={i}>
        <CheckboxGroup
          name="positions"
          className={validate('positions', validationErrors).className}
          items={positions}
          selected={selected}
          onChange={internalOnChange} />
      </div>
    );
  }
  return (<div className="job-positions-list row">{output}</div>);
};

const renderLanguagesList = (languages = [], selected = [], validationErrors, onChange = () => {}) => {
  if(!languages.length) return null;
  languages = languages.map(language => {
    language.label = language.LanguageDesc;
    language.value = language.LanguageId;
    return language;
  });
  const internalOnChange = values => onChange('languages', values);
  return (
    <CheckboxGroup
      name="languages"
      className={validate('languages', validationErrors).className}
      items={languages}
      selected={selected}
      onChange={internalOnChange} />
  );
};

const renderAgencyHistory = (workedForAgencies, agencies = [], validationErrors, onChange) => {
  if(!workedForAgencies || workedForAgencies === 'N') return null;
  const changeHandler = (index, name, value) => {
    const agenciesCopy = [].concat(agencies);
    agenciesCopy[index][name] = value;
    onChange(agenciesCopy);
  };

  const rows = [];
  for(let i = 0; i < 3; i += 1) {
    let agency = agencies[i];
    let inputId = uniqueId('agency');
    if(!agency) {
      agency = {};
      agencies.push(agency);
    }
    rows.push(
      <div className="row" key={i}>
        <div className="col-md-2">
          <label htmlFor={`name${inputId}`}>Agency {i + 1}</label>
          <input
            id={`name${inputId}`}
            name='name'
            value={agency.name || ''}
            className="form-control"
            onChange={({target: {name, value}}) => {changeHandler(i, name, value)}} />
        </div>
        <div className="col-md-3">
          <label htmlFor={`company_assigned${inputId}`}>Company they sent you to</label>
          <input
            id={`company_assigned${inputId}`}
            name='company_assigned'
            value={agency.company_assigned || ''}
            className="form-control"
            onChange={({target: {name, value}}) => {changeHandler(i, name, value)}} />
        </div>
        <div className="col-md-3">
          <label htmlFor={`type_of_work${inputId}`}>Job Title</label>
          <input
            id={`type_of_work${inputId}`}
            name='type_of_work'
            value={agency.type_of_work || ''}
            className="form-control"
            onChange={({target: {name, value}}) => {changeHandler(i, name, value)}} />
        </div>
        <div className="col-md-1">
          <label htmlFor={`wage${inputId}`}>Wage</label>
          <input
            id={`wage${inputId}`}
            name='wage'
            value={agency.wage || ''}
            className="form-control"
            onChange={({target: {name, value}}) => {changeHandler(i, name, value)}} />
        </div>
        <div className="col-md-3">
          <label htmlFor={`supervisor${inputId}`}>Supervisor at Company</label>
          <input
            id={`supervisor${inputId}`}
            name='supervisor'
            value={agency.supervisor || ''}
            className="form-control"
            onChange={({target: {name, value}}) => {changeHandler(i, name, value)}} />
        </div>
      </div>
    );
  }
  //const rows = agencies.map((agency, i) => (

  //));
  return(
    <div>
      <p className="qstn">Please list the agencies and work assignment info:</p>
      {rows}
    </div>
  );
};

function TelInput(props) {
  return (
    <Cleave
      {...props}
      onKeyDown={(e) => {
        if(!((e.keyCode === 8) || e.keyCode >= 48 && e.keyCode <=57) || (e.keyCode >= 96 && e.keyCode <=105)) {
          e.preventDefault();
        }
      }}
      options={{
        blocks: [3, 3, 4],
        delimiter: '-'
      }}
    />
  );
}

const renderWorkHistory = (formValues, onChange) => {
  const output = [];
  for(let i = 1; i <= 2; i += 1) {
    const company = formValues[`work_history_company_${i}`] || '';
    const tel = formValues[`work_history_tel_${i}`] || '';
    const title = formValues[`work_history_title_${i}`] || '';
    const duties = formValues[`work_history_duties_${i}`] || '';
    output.push(
      <div className="row" key={i}>
        <div className="col-md-4">
          <label htmlFor={`work_history_company_${i}`}>Company {i}</label>
          <input id={`work_history_company_${i}`} name={`work_history_company_${i}`} value={company} className="form-control" onChange={onChange} />
        </div>
        <div className="col-md-2">
          <label htmlFor={`work_history_tel_${i}`}>Tel</label>
          <TelInput
            required
            className="form-control"
            id={`work_history_tel_${i}`}
            name={`work_history_tel_${i}`}
            value={tel}
            onChange={onChange}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor={`work_history_title_${i}`}>Job Title</label>
          <input id={`work_history_title_${i}`} name={`work_history_title_${i}`} value={title} className="form-control" onChange={onChange} />
        </div>
        <div className="col-md-3">
          <label htmlFor={`work_history_duties_${i}`}>Duties</label>
          <input id={`work_history_duties_${i}`} name={`work_history_duties_${i}`} value={duties} className="form-control" onChange={onChange} />
        </div>
      </div>
    );
  }
  return output;
};

class Form extends Component {
  state = {
    formValues: {province: '1', languages: ''},
    dobFocused: false,
    sinExpDateFocused: false,
    workPermitExpDateFocused: false,
    disclaimerAgreed: false
  };

  constructor(props) {
    super(...arguments);
    this.submitForm = e => {
      e.preventDefault();
      const {formValues} = this.state;
      if(formValues.dob) {
        formValues.dob = moment(formValues.dob).format('YYYY-MM-DD');
      }
      if(formValues.sin_exp_date) {
        formValues.sin_exp_date = moment(formValues.sin_exp_date).format('YYYY-MM-DD');
      }
      if(formValues.work_permit_exp_date) {
        formValues.work_permit_exp_date = moment(formValues.work_permit_exp_date).format('YYYY-MM-DD');
      }
      if(formValues.date_of_availability) {
        formValues.date_of_availability = moment(formValues.date_of_availability).format('YYYY-MM-DD');
      }
      props.submitForm(formValues);
    };

    this.onChange = (name, value) => {
      const formValues = this.state.formValues;
      formValues[name] = value;
      this.setState({formValues});
    };
  }

  componentDidMount() {
    const {formValues, validationErrors} = this.props;
    let values = Object.assign({}, this.state.formValues, formValues);
    const dates = ['date_of_availability', 'dob', 'sin_exp_date', 'work_permit_exp_date'];
    this.setState({formValues: values, validationErrors});
    this.props.getCitiesList(1);
  }

  componentWillUnmount() {}

  render() {
    const { props, state } = this;
    const { formValues } = state;
    const { validationErrors } = props;
    const onChange = ({target: {name, value}}) => this.onChange(name, value);
    return (
      <form onSubmit={this.submitForm}>
        <fieldset className="form-group">
          <legend>Personal Information</legend>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="first_name" className="qstn">First Name*</label><br />
              <input type="text" required id="first_name" value={formValues['first_name'] || ''} name="first_name" onChange={onChange} className={validate('first_name', validationErrors, 'form-control').className} />
            </div>
            <div className="col-md-4">
              <label htmlFor="middle_name" className="qstn">Middle Name</label><br />
              <input type="text" id="middle_name" value={formValues['middle_name'] || ''} name="middle_name" onChange={onChange} className="form-control" />
            </div>
            <div className="col-md-4">
              <label htmlFor="last_name" className="qstn">Last Name*</label><br />
              <input type="text" required id="last_name" value={formValues['last_name'] || ''} name="last_name" onChange={onChange} className={validate('last_name', validationErrors, 'form-control').className}/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="address" className="qstn">Address*</label>
              <input type="text" required id="address" value={formValues['address'] || ''} name="address" onChange={onChange} className={validate('address', validationErrors, 'form-control').className}/>
            </div>
            <div className="col-md-3">
              <label htmlFor="city" className="qstn">City*</label>
              <SelectInput requried
                items={this.props.cities}
                valueProp="City_Id"
                labelProp="City_Name"
                className={validate('city', validationErrors, 'form-control').className}
                name="city"
                id="city"
                selected={this.state.formValues.city}
                onChange={onChange}
              />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="province" className="qstn">Province</label>
                  <SelectInput required
                    items={[{id: 1, label: 'Ontario'}]}
                    selected={formValues.province}
                    valueProp="id"
                    labelProp="label"
                    className="form-control"
                    disabled={true}
                    name="province"
                    id="province"
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="postal_code" className="qstn">Postal Code</label>
                  <input required type="text" id="postal_code" value={formValues['postal_code'] || ''} name="postal_code" onChange={onChange}
                    className={validate('postal_code', validationErrors, 'form-control').className} />
                </div>
                <div className="col-md-4">
                  <label htmlFor="intersection" className="qstn">Major Intersection*</label>
                  <input type="text" value={formValues['intersection'] || ''} id="intersection" name="intersection" onChange={onChange}
                         className={validate('intersection', validationErrors, 'form-control').className}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="tel_home" className="qstn">Tel (Home#)*</label>
              <TelInput
                required
                id="tel_home"
                name="tel_home"
                value={formValues['tel_home'] || ''}
                className={validate('tel_home', validationErrors, 'form-control').className}
                onChange={onChange}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="tel_alternative" className="qstn">Alternate/Cell Phone #</label>
              <TelInput
                required
                id="tel_alternative"
                name="tel_alternative"
                value={formValues['tel_alternative'] || ''}
                className={validate('tel_alternative', validationErrors, 'form-control').className}
                onChange={onChange}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="email" className="qstn">Email</label>
              <input readOnly type="email" id="email" value={formValues['email'] || ''} name="email" onChange={onChange} className={validate('email', validationErrors, 'form-control').className} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div>
                <label htmlFor="dob" className="qstn">Date of Birth*</label>
              </div>
              <DatePicker
                selected={formValues.dob ? moment(formValues.dob, 'YYYY-MM-DD').toDate() : null}
                dateFormat="yyyy-MM-dd"
                className={validate('dob', validationErrors, 'form-control').className}
                onChange={date => {
                  const newFormValues = this.state.formValues;
                  newFormValues.dob = date;
                  this.setState({formValues: newFormValues})
                }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="sin" className="qstn">SIN</label>
              <input type="text" id="sin" defaultValue={formValues['sin']} name="sin" onChange={onChange} className={validate('sin', validationErrors, 'form-control').className} />
            </div>
            <div className="col-md-4">
              <div>
                <label htmlFor="sin_exp_date" className="qstn">SIN Expiry Date</label>
              </div>
              <DatePicker
                id="sin_exp_date"
                selected={formValues.sin_exp_date ? moment(formValues.sin_exp_date, 'YYYY-MM-DD').toDate() : null}
                dateFormat="yyyy-MM-dd"
                todayButton="Today"
                minDate={new Date()}
                className={validate('sin_exp_date', validationErrors, 'form-control').className}
                onChange={date => {
                  const formValues = this.state.formValues;
                  formValues.sin_exp_date = date;
                  this.setState({formValues})
                }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="work_permit_bb" className="qstn">If you have a Work Permit: Work Permit # DD</label>
              <input type="text" id="work_permit_bb" value={formValues['work_permit_bb'] || ''} name="work_permit_bb" onChange={onChange}
                     className="form-control"/>
            </div>
            <div className="col-md-4">
              <div>
                <label htmlFor="work_permit_exp_date" className="qstn">Work Permit Expiry Date</label>
              </div>
              <DatePicker
                id="work_permit_exp_date"
                selected={formValues.work_permit_exp_date ? moment(formValues.work_permit_exp_date, 'YYYY-MM-DD').toDate() : null}
                minDate={new Date()}
                dateFormat="yyyy-MM-dd"
                todayButton="Today"
                className="form-control"
                onChange={date => {
                  const formValues = this.state.formValues;
                  formValues.work_permit_exp_date = date;
                  this.setState({formValues})
                }} />
            </div>
          </div>
          <div>
            <p className="qstn">In case of emergency or injury who should be contacted:</p>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="emergency_contact_name">Name*</label>
                <input type="text" id="emergency_contact_name" value={formValues['emergency_contact_name'] || ''} name="emergency_contact_name" onChange={onChange}
                       className={validate('emergency_contact_name', validationErrors, 'form-control').className}/>
              </div>
              <div className="col-md-4">
                <label htmlFor="emergency_contact_phone">Phone*</label>
                <TelInput
                  required
                  id="emergency_contact_phone"
                  name="emergency_contact_phone"
                  value={formValues['emergency_contact_phone'] || ''}
                  className={validate('emergency_contact_phone', validationErrors, 'form-control').className}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="qstn">Are you entitled to work in Canada at the present time?*</p>
              <RadioGroup
                className={validate('legal_to_work', validationErrors).className}
                name="legal_to_work"
                items={YES_NO}
                selected={formValues.legal_to_work}
                onChange={value => this.onChange('legal_to_work', value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="referrer" className="qstn">How did you hear about us?*</label>
              <input type="text" id="referrer" value={formValues['referrer'] || ''} name="referrer" onChange={onChange}
                className={validate('referrer', validationErrors, 'form-control').className} />
            </div>
          </div>
          <div>
            <p className="qstn">Do you have access to a vehicle or will you be taking the bus?*</p>
            <RadioGroup
              name="vehicle_access"
              className={validate('vehicle_access', validationErrors).className}
              items={YES_NO}
              selected={formValues.vehicle_access}
              onChange={value => this.onChange('vehicle_access', value)}
            />
          </div>
          <div>
            <p className="qstn">Do you have safety shoes?*</p>
            <RadioGroup
              name="safety_shoes"
              className={validate('safety_shoes', validationErrors).className}
              items={YES_NO}
              selected={formValues.safety_shoes}
              onChange={value => this.onChange('safety_shoes', value)}
            />
          </div>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="pounds_lift" className="qstn">How many pounds can you lift on average?*</label>
              <input type="number" id="pounds_lift" value={formValues['pounds_lift'] || ''} name="pounds_lift" onChange={onChange}
                className={validate('pounds_lift', validationErrors, 'form-control').className}/>
            </div>
          </div>
          <div>
            <p className="qstn">Have you been charged with an indictable offense for which you have not been pardoned?*</p>
            <RadioGroup
              items={YES_NO}
              className={validate('offence', validationErrors).className}
              selected={formValues.offence}
              name="offence"
              onChange={value => this.onChange('offence', value)}
            />
          </div>
          <div>
            <p className="qstn">How well can you speak English?</p>
            <RadioGroup
              items={SPEAK_ENG}
              className={validate('speak_eng', validationErrors).className}
              selected={formValues.speak_eng}
              name="speak_eng"
              onChange={value => this.onChange('speak_eng', value)}
            />
          </div>
          <div>
            <p className="qstn">How well can you write English?</p>
            <RadioGroup
              items={SPEAK_ENG}
              className={validate('write_eng', validationErrors).className}
              selected={formValues.write_eng}
              name="write_eng"
              onChange={value => this.onChange('write_eng', value)}
            />
          </div>
          <div>
            <p className="qstn">Are you able to work fast?*</p>
            <RadioGroup
              items={YES_NO}
              selected={formValues.work_fast}
              className={validate('work_fast', validationErrors).className}
              name="work_fast"
              onChange={value => this.onChange('work_fast', value)}
            />
          </div>
          <div>
            <p className="qstn">Are you able to handle a job that requires lifting?*</p>
            <RadioGroup
              items={YES_NO}
              selected={formValues.can_lift}
              name="can_lift"
              className={validate('can_lift', validationErrors).className}
              onChange={value => this.onChange('can_lift', value)} />
          </div>
          <div>
            <p className="qstn">If you cannot work with certain products, please list here: (i.e. meat, alcohol)</p>
            <CheckboxGroup
              items={AVOID_PRODUCT}
              className={validate('avoid_product', validationErrors).className}
              name='avoid_product'
              selected={formValues.avoid_product}
              onChange={values => this.onChange('avoid_product', values)} />
          </div>
          <div>
            <p className="qstn">If you allergic to anything please list it here</p>
            <input type="text" value={formValues['allergies'] || ''} className="form-control" name="allergies" onChange={onChange}/>
          </div>
          <div>
            <p className="qstn">Have you worked for another Employment Agency?*</p>
            <RadioGroup
              items={YES_NO}
              selected={formValues.worked_for_another}
              name="worked_for_another"
              className={validate('worked_for_another', validationErrors).className}
              onChange={value => this.onChange('worked_for_another', value)}
            />
          </div>
          <div className="form-group">
            {renderAgencyHistory(formValues.worked_for_another, formValues.agencies, validationErrors, value => this.onChange('agencies', value))}
          </div>
        </fieldset>
        <fieldset>
          <legend>Applying For</legend>
          <div><p className="qstn">What position are you applying for:</p></div>
          {renderJobPositionsList(props.jobPositions, formValues.positions, validationErrors, this.onChange)}
        </fieldset>
        <div>
          <p className="qstn">Hours of work you are willing to accept:*</p>
          <CheckboxGroup
            name="hours_type"
            items={HOURS_TYPE}
            className={validate('hours_type', validationErrors).className}
            selected={formValues.hours_type}
            onChange={values => this.onChange('hours_type', values)}
          />
        </div>
        <div>
          <p className="qstn">Type of employment you are willing to accept:*</p>
          <CheckboxGroup
            name="employment_type"
            items={EMPLOYMENT_TYPE}
            className={validate('employment_type', validationErrors).className}
            selected={formValues.employment_type}
            onChange={values => this.onChange('employment_type', values)}
          />
        </div>
        <div>
          <p className="qstn">Type of Shift you are willing to accept:*</p>
          <CheckboxGroup
            items={SHIFT_TYPES}
            name="shift_types"
            className={validate('shift_types', validationErrors).className}
            selected={formValues.shift_types}
            onChange={values => this.onChange('shift_types', values)}
          />
        </div>
        <div>
          <p className="qstn">Minimum Salary Requirement (Please enter the wage you will accept)*</p>
          <div className="row">
            <div className="col-md-2">
              <input
                type="number"
                value={formValues['min_salary'] || ''}
                name="min_salary"
                onChange={onChange}
                className={validate('min_salary', validationErrors, 'form-control').className} />
            </div>
            <div className="col-md-10"> per hour and higher.</div>
          </div>
          <small>We will not contact you for any positions that are lower than the minimum wage you entered.</small>
        </div>
        <div>
          <p className="qstn">When can you start work?*</p>
          <label htmlFor="date_of_availability">Pick a date</label>
          <DatePicker
            id="date_of_availability"
            selected={formValues.date_of_availability ? moment(formValues.date_of_availability, 'YYYY-MM-DD').toDate() : null}
            minDate={new Date()}
            dateFormat="yyyy-MM-dd"
            todayButton="Today"
            className={validate('date_of_availability', validationErrors, 'form-control').className}
            onChange={date => {
              const formValues = this.state.formValues;
              formValues.date_of_availability = date;
              this.setState({formValues})
            }} />
        </div>
        <fieldset>
          <legend>Education & Skills*</legend>
          <div>
            <p className="qstn">Highest Level of Education you have completed:</p>
            <RadioGroup
              items={EDUCATION_TYPES}
              className={validate('educations', validationErrors).className}
              selected={formValues.educations}
              name="educations"
              onChange={values => this.onChange('educations', values)}
            />
          </div>
          <div>
            <p className="qstn">Are you certified in anything?</p>
            <input type="text" name="certificates" value={formValues['certificates'] || ''} onChange={onChange} className="form-control"/>
          </div>
        </fieldset>
        <fieldset>
          <legend>Work History</legend>
          {renderWorkHistory(formValues, onChange)}
          <div className="languages-list">
            <p>What language can you speak?</p>
            {renderLanguagesList(props.languages, formValues.languages, validationErrors, this.onChange)}
          </div>
        </fieldset>
        <div className="disclaimer">
          <label>
            <input type="checkbox"
              onChange={({target}) => {
                const {checked} = target;
                this.setState({disclaimerAgreed: checked});
              }}
            />&nbsp;
            I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that
            false or misleading information in my application or interview may result in my release.
          </label>
        </div>
        <Button
          className="btn btn-primary btn-submit btn-lg"
          onClick={this.submitForm}
          disabled={!this.state.disclaimerAgreed}
          loading={props.loading}>Submit &amp; Continue</Button>
      </form>
    );
  }
};

function mapStateToProps({workers: state}) {
  return {
    cities: state.resources.cities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCitiesList: (provinceId) => dispatch(action.getCitiesList(provinceId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
