import React, { Component } from 'react';

export default class Tabbar extends Component {
  getTabs() {
    return this.props.tabs.map(({label, value}, index) => {
      let classNames = "btn btn-outline-primary";
      if(this.props.activeTab === value) {
        classNames += ' active';
      }
      return (
        <button key={index} className={classNames} onClick={() => {
          if(this.props.activeTab !== value) this.props.onTabChange(value);
        }}>{label}</button>
      );
    });
  }
  render() {
    if(!this.props.tabs) return null;
    return (
      <div className="Tabbar btn-group">{this.getTabs()}</div>
    );
  }
}
