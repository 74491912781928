import React from 'react';
import PropTypes from 'prop-types';

export default function LoadSpinner({loading, showLabel = true}) {
  if(!loading) return null;
  const label = showLabel ? ' Loading' : null;
  return (
    <React.Fragment><i className='icon-spin2 animate-spin'></i>{label}</React.Fragment>
  );
}

LoadSpinner.propTypes = {
  loading: PropTypes.bool
};
