import axios  from 'axios';
import config from '../config';

async function fetch(userId) {
  let items = [];

  try {
    const {data} = await axios.get( `${ config.API.URL }checklists/${userId}/`);
    items = data;
  } catch(e) {
    console.error(e);
  }
  return items;
}

export default {fetch};
