import React from 'react';
import _ from 'lodash';
import DownloadDocumentButton from './DownloadDocumentButton';
import DeleteDocumentButton from './DeleteDocumentButton';
import UnDeleteDocumentButton from './UnDeleteDocumentButton';

const getStatusIcon = (items, key) => {
  return null;
  /*
  let icon = null;
  const {status} = items.find(i => i.key === key) || {};
  if(status) {
    icon = (<i className="icon icon-ok"></i>);
  }
  return icon;
  */
};
export default function Checklist({applicationId, userId, items, onDataChange}) {
  const terms = _.find(items, (i => i.key === 'terms'));
  const taxCredit = _.find(items, (i => i.key === 'tax-credit'));
  const resume = _.find(items, (i => i.key === 'resume'));
  const gmp = _.find(items, (i => i.key === 'manufacturing-policy'));

  return (
    <div className="Checklist">
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div style={{display: 'flex'}}>
            {getStatusIcon(items, 'application')} Application
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              <a
                className="btn btn-sm btn-info"
                href={`http://olastaffing.com/admin/index.php?option=forms&form_id=32&JobApplicationId=${applicationId}`}
                rel="noopener noreferrer"
                target="_blank">
               View</a>
             </div>
           </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex'}}>
            {getStatusIcon(items, 'tax-credit')} Resume
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              {(() => {
                if(!resume || !resume.document_id || resume.deleted) return null;
                return <DownloadDocumentButton documentId={resume.document_id} />
              })()}
              {(() => {
                if(!resume || !resume.document_id || resume.deleted) return null;
                return <DeleteDocumentButton documentId={resume.document_id} onComplete={onDataChange} />
              })()}

              {(() => {
                if(!resume || !resume.document_id || !resume.deleted) return null;
                return <UnDeleteDocumentButton documentId={resume.document_id} onComplete={onDataChange} />
              })()}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex'}}>
            {getStatusIcon(items, 'terms')} Terms and Conditions&nbsp;
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              {(() => {
                if(!terms || !terms.document_id || terms.deleted) return null;
                return <DownloadDocumentButton documentId={terms.document_id} />
              })()}
              {(() => {
                if(!terms || !terms.document_id || terms.deleted) return null;
                return <DeleteDocumentButton documentId={terms.document_id} onComplete={onDataChange} />
              })()}
              {(() => {
                if(!terms || !terms.document_id || !terms.deleted) return null;
                return <UnDeleteDocumentButton documentId={terms.document_id} onComplete={onDataChange} />
              })()}

            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex'}}>
            {getStatusIcon(items, 'tax-credit')} Personal Tax Credit&nbsp;
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              {(() => {
                if(!taxCredit || !taxCredit.document_id || taxCredit.deleted) return null;
                return <DownloadDocumentButton documentId={taxCredit.document_id} />
              })()}
              {(() => {
                if(!taxCredit || !taxCredit.document_id || taxCredit.deleted) return null;
                return <DeleteDocumentButton documentId={taxCredit.document_id} onComplete={onDataChange} />
              })()}

              {(() => {
                if(!taxCredit || !taxCredit.document_id || !taxCredit.deleted) return null;
                return <UnDeleteDocumentButton documentId={taxCredit.document_id} onComplete={onDataChange} />
              })()}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div>{getStatusIcon(items, 'health-safety')} Health &amp; Safety at Work</div>
            <div>
              {(() => {
              const quiz = _.find(items, ({key}) => key === 'health-safety');
              if(!quiz) return null;
              return quiz.score;
              })()}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div>{getStatusIcon(items, 'whmis')} WHMIS 2015 Testing</div>
            <div>
              {(() => {
              const quiz = _.find(items, ({key}) => key === 'whmis');
              if(!quiz) return null;
              return quiz.score;
              })()}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div style={{display: 'flex'}}>
            {getStatusIcon(items, 'manufacturing-policy')} GMP Policy
            <div className="btn-group" style={{marginLeft: 'auto'}}>
              {(() => {
                if(!gmp || !gmp.document_id || gmp.deleted) return null;
                return <DownloadDocumentButton documentId={gmp.document_id} />
              })()}
              {(() => {
                if(!gmp || !gmp.document_id || gmp.deleted) return null;
                return <DeleteDocumentButton documentId={gmp.document_id} onComplete={onDataChange} />
              })()}
              {(() => {
                if(!gmp || !gmp.document_id || !gmp.deleted) return null;
                return <UnDeleteDocumentButton documentId={gmp.document_id} onComplete={onDataChange} />
              })()}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
