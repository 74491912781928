import React, { Component } from 'react';
import moment from 'moment';
import qs from 'qs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { admin as action } from '../../../actions';
import Pagination from '../../Pagination';
import LoadSpinner from '../../LoadSpinner';

import SearchForm from './SearchForm';
import Details from './Details';
import UploadZone from './UploadZone';
import Attachments from './Attachments';
import './index.scss';

const getGender = symbol => (symbol.toLowerCase() === 'm') ? 'Male' : (symbol.toLowerCase() === 'f') ? 'Female' : symbol;

class Applications extends Component {
  state = {
    currentPage: 1,
    selectedRow: null,
    searchFilters: {}
  };

  constructor() {
    super(...arguments);
    this.handleSearch = formValues => {
      this.props.history.replace(`/admin/applications?${qs.stringify(formValues)}`);
      this.setState({searchFilters: formValues}, () => this.loadApplications(1));
    }
  }

  componentDidMount() {
    const searchFilters = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    this.setState({searchFilters}, () => {
      this.loadApplications(this.state.currentPage);
    });
  }

  loadApplications(page) {
    this.props.getApplications(page, this.state.searchFilters);
  }

  applicationClick(application) {
    this.setState({selectedRow: {applicationId: application.id, userId: application.user_id}});
    this.props.getDetails(application.user_id);
    this.props.getAttachments(application.user_id);
  }

  render() {
    let applications = null;
    if(!this.props.applications.length && !this.props.loading) {
      applications = (
        <tr><td colSpan="6">No Applications found</td></tr>
      );
    } else if(this.props.applications.length) {
      applications = this.props.applications.map((a, index) => {
        const {selectedRow} = this.state;
        const active = (selectedRow && selectedRow.applicationId === a.id) ? 'active': '';
        return(
          <tr key={index} onClick={() => this.applicationClick(a)} className={active}>
            <td>{a.first_name}</td>
            <td>{a.last_name}</td>
            <td>{a.gender && getGender(a.gender)}</td>
            <td>{moment().diff(a.dob, 'years')}</td>
            <td>{a.city}</td>
            <td>{Number(a.min_salary).toFixed(2)}</td>
          </tr>
        );
      });
    }
    return(
      <div className="Applications">
        <h1>Applications</h1>
        <div className="row">
          <div className="col-md-8">
            <SearchForm
              onSearchClick={this.handleSearch}
              defaultValues={this.state.searchFilters}
              loading={this.props.loading} />
            <table className="table table-striped table-borderless">
              <thead className="thead-dark"><tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Gender</th>
                  <th>Age</th>
                  <th>City</th>
                  <th>Min. Salary</th>
              </tr></thead>
              <tbody>
                {applications}
              </tbody>
            </table>
            <Pagination
              total={this.props.total}
              currentPage={this.state.currentPage}
              limit={this.props.limit}
              onPageClick={page => {
                this.setState({currentPage: page});
                this.loadApplications(page);
              }} />
          </div>
          <div className="col-md-4">
            <div className="sticky-top">
              {(() => {
                if(this.state.selectedRow) {
                  if(this.props.details.loading) {
                    return (
                      <div>
                        <h5>Details</h5>
                        <LoadSpinner loading={true} />
                      </div>
                    );
                  }
                  return (
                    <React.Fragment>
                      <Details />
                      <Attachments
                        userId={this.state.selectedRow.userId}
                        onChange={() => this.props.getAttachments(this.state.selectedRow.userId)}
                      />
                      <UploadZone userId={this.state.selectedRow.userId} onComplete={() => {
                        this.props.getDetails(this.state.selectedRow.userId);
                        this.props.getAttachments(this.state.selectedRow.userId);
                      }} />
                    </React.Fragment>
                  );
                }
                return null;
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({admin: state}) {

  const {applications: {applications, loading, total, limit, details}} = state;
  return {
    applications,
    details,
    total,
    limit,
    loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApplications: (page, params) => dispatch(action.getApplications(page, params)),
    getDetails: userId => dispatch(action.getApplicationDetails(userId)),
    getAttachments: userId => dispatch(action.getAttachments(userId))
  };
}

Applications.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  limit: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
