import axios from 'axios';
import config from '../config';

const employee = {
  async register(formData) {
    if(!formData.email.length || !formData.password.length) {
      throw new Error('Missing required fields');
    }
    let response = null;
    try {
      const {data} = await axios.post( `${ config.API.URL }users/register/`, formData );
      response = data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    }
    return response;
  },

  async passwordReset(formData) {
    if(!formData.email.length) {
      throw new Error('Missing required fields');
    }
    let response = null;
    try {
      const {data} = await axios.post( `${ config.API.URL }users/password-reset-request/`, formData );
      response = data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    }
    return response;
  },

  async passwordResetStep2Fetch(token) {
    if(!token.length) {
      throw new Error('Missing required fields');
    }
    let response = null;
    try {
      const {data} = await axios.get( `${ config.API.URL }users/password-reset-validate-token/?token=${token}` );
      response = data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    }
    return response;
  },

  async passwordResetStep2Submit(formData) {
    if(!formData.password.length || !formData.token.length) {
      throw new Error('Missing required fields');
    }
    let response = null;
    try {
      const {data} = await axios.post( `${ config.API.URL }users/password-reset-update-password/`, formData );
      response = data;
    } catch(err) {
      const res = err.response;
      let error = err;
      if( res && res.data ) {
        error = res.data.error;
      }
      throw new Error( error );
    }
    return response;
  }
}

export default employee;
