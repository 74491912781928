import axios from 'axios';
import config from '../config';

export default {
  getJobPositions() {
    return axios.get(`${ config.API.URL }job-positions/`)
      .then(res => res.data);
  },

  getLanguagesList() {
    return axios.get(`${ config.API.URL }languages/`)
      .then(res => res.data);
  },

  getCitiesList(provinceId) {
    if(!provinceId) Promise.reject(new Error('Province id is required.'));
    return axios.get(`${ config.API.URL }cities/${ provinceId }`)
      .then(res => res.data);
  }
}
