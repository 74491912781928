import axios from 'axios';
import config from '../../config';

export default {
  async paginate(page, filters = {}) {
    let response = null;
    const limit = 20;
    const offset = (page * limit) - limit;
    const params = Object.assign({}, {...filters}, {offset, limit});
    try {
      const {data, total, limit} = await axios.get(`${ config.API.URL }admin/notifications/`, {params} );
      response = {data, total, limit};
    } catch(err) {
      throw err;
    }
    return response;
  },

  async markReadStatus(id, status) {
    let response = null;
    try {
      const {data} = await axios.put(`${ config.API.URL }admin/notifications/${id}/read/`, {status} );
      response = data;
    } catch(err) {
      throw err;
    }
    return response;
  }
}
