import axios  from 'axios';
import moment from 'moment';
import config from '../config';
import preload from './preload';

axios.interceptors.response.use((( { data } ) => {
  if ( data.error ) throw new Error( data.error );
  return data;
}), ( err ) => {
  if(err instanceof Error) throw err;
});

const authentication = {
  /**
  * Log the user in.
  */
  login (email = null, password = null ) {
    localStorage.clear();

    return axios.post( `${ config.API.URL }auth/token/`, { email, password } )
    .then (( {token, application_id, expires, user: {role, email, user_id}})=> {
      let userRole = null;
      if(role === 'worker') {
        userRole = 'worker';
      } else if(role === 'S') {
        userRole = 'super_admin';
      }
      localStorage.setItem( 'jwtToken', token );
      localStorage.setItem( 'jwtExpire', expires );
      localStorage.setItem( 'userRole', userRole );
      localStorage.setItem( 'userProfile', JSON.stringify({
        email,
        userId: user_id
      }));
      //localStorage.setItem( 'userEmail', email );
      if(application_id) localStorage.setItem( 'applicationId', application_id );
    } )
    .then( this.setTokenHeader )
    .then( () => preload() )
    .catch( res => {
      let error = res;

      if(res && res.response && res.response.data && res.response.data.error) {
        error = res.response.data.error;
      }
      throw new Error( error );
    } );
  },


  /**
  * Set token to http request header.
  */
  logout() {
    localStorage.clear();
    return Promise.resolve(true);
  },

  /**
  * Set token to http request header.
  */
  setTokenHeader() {
    let token = localStorage.getItem( 'jwtToken' );
    axios.defaults.headers.common[ 'Authorization' ] = `Bearer ${ token }`;
  },

  /**
  * Check if token is not expired.
  */
  loggedIn() {
    let token          = localStorage.getItem( 'jwtToken' );
    let expireDateTime = localStorage.getItem( 'jwtExpire' );

    if( !token || !expireDateTime ) {
      return false;
    }

    return moment( expireDateTime ).isAfter( moment() );
  },

  /**
   * Check if user is super admin
   */
  superAdmin() {
    return localStorage.getItem('userRole') === 'super_admin';
  },

  /**
   * Get user profile
   */
  getProfile() {
    let profile = {};
    try {
      profile = JSON.parse(localStorage.getItem('userProfile'));
    } catch(e) {
      profile = {};
    }
    return profile;
  }

};

if(authentication.loggedIn()) authentication.setTokenHeader();

export default authentication;
