import React, { Component } from 'react';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { workers as action } from '../../actions';
import Message from '../Message';
import LoadSpinner from '../LoadSpinner';
import { nextChecklistItem } from '../../utils';

const getStatusIcon = (status) => {
  let icon = null;
  if(status) {
    icon = (<i className="icon icon-ok"></i>);
  }
  return icon;
};

const getPath = (key) => {
  const paths = {
    'application': 'application',
    'resume': 'resume',
    'terms': 'terms-and-conditions',
    'tax-credit': 'personal-tax-credits',
    'health-safety': 'health-and-safety',
    'whmis': 'whmis',
    'manufacturing-policy': 'good-manufacturing-policy'
  };
  return paths[key] || '';
};

const items = checklist => {
  if(!checklist || !checklist.length) return null;
  const application = _.find(checklist, i => i.key === 'application');

  return checklist.map((item, index) => {
    if(item.key === 'application' || application.status) {
      let link = getPath(item.key);
      if(!link) {
        return (
          <div key={item.key} className="list-group-item list-group-item-action disabled">
            {getStatusIcon(item.status)} {item.name}
          </div>
        );
      }
      return (
        <Link key={index} to={getPath(item.key)} className="list-group-item list-group-item-action">
          {getStatusIcon(item.status && item.deleted !== true)} {item.name}
        </Link>
      );
    }
    return (
      <div key={item.key} className="list-group-item list-group-item-action disabled">{item.name}</div>
    );
  });
};

function Home(props) {
  const nextPendingChecklistItem = nextChecklistItem(props.checklistItems);
  if(nextPendingChecklistItem !== null) {
    return (
      <Redirect to={nextPendingChecklistItem} />
    );
  }
  return(
    <div className="Home">
      <div className="row">
        <div className="col-md-6">
          <h1>Checklist</h1>
          {(() => {
            if(!props.allCompleted) return null;
            return (
              <React.Fragment>
                <Message content='Thank you for Registering with OLA Staffing.' type="success" />
                <Message content='One of our Recruiters will review your documents and be in contact with you shortly. Should you have any questions or concerns, please contact our office at 416-743-9675.' type="info" />
              </React.Fragment>
            );
          })()}
          <div><LoadSpinner loading={props.loading} showLabel={false} /></div>
          <div className="list-group list-group-flush">
            {items(props.checklistItems)}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({workers: state}) {
  const { checklist: { items, loading, allCompleted }} = state;
  return {
    checklistItems: items,
    loading,
    allCompleted
  };
}

export default connect(mapStateToProps)(Home);
