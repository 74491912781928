import React, { Component } from 'react';
import Notifications from '../notifications';

export class Dashboard extends Component {
  render() {
    return(
      <div className="Dashboard">
        <h1>Dashboard</h1>
        <div className="row">
          <div className="col-md-12">
            <Notifications />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
