import React, { Component } from 'react';
import Button from '../../Button';
import doc from '../../../services/admin/doc';

export default class DownloadDocumentButton extends Component {
  state = {
    loading: false
  };

  downloadDocument() {
    const {documentId} = this.props;
    this.setState({loading: true});
    doc.download(documentId)
      .then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <Button
        className="btn btn-sm btn-info"
        loading={this.state.loading}
        onClick={() => this.downloadDocument.call(this)}>Download</Button>
    );
  }
}
