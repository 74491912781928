import React from 'react';

export default () => {
  return(
    <div className="PageNotFound">
      <div className="row">
        <div className="col-md-6">
          <h1>404</h1>
          <div>Page Not Found</div>
        </div>
      </div>
    </div>
  );
}
